/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
// import course1 from "assets/images/courses/course1.JPG";
// import course2 from "assets/images/courses/course2.JPG";
// import course3 from "assets/images/courses/course3.JPG";
// import course4 from "assets/images/courses/course4.JPG";
// import course5 from "assets/images/courses/course5.JPG";
// import course6 from "assets/images/courses/course6.JPG";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

export default function data(tableData, callback) {
  console.log("tableData", tableData);
  const editPackage = (id) => {
    callback(id, "EDIT");
  };
  const deletePackage = (id) => {
    callback(id, "DELETE");
  };
  const Author = ({ name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );

  const Job = ({ courses }) => (
    <MDBox lineHeight={1} textAlign="left">
      {console.log("title", courses)}
      {courses?.map((item, i) => (
        <MDTypography
          display="block"
          variant="caption"
          color="text"
          fontSize="10px"
          marginY={2}
          fontWeight="medium"
          key={i}
        >
          {i + 1} .{item.title}
        </MDTypography>
      ))}
    </MDBox>
  );
  const PackageImage = ({ packageImage }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}>
        {packageImage && (
          <img
            src={packageImage}
            alt=""
            style={{ width: "100px", height: "100px", borderRadius: "10px" }}
          />
        )}
        {/* <MDTypography variant="caption">{email}</MDTypography> */}
      </MDBox>
    </MDBox>
  );

  return {
    cols: [
      { Header: "#", accessor: "no", align: "left", width: "5%" },
      { Header: "package Image", accessor: "packageImage", align: "left" },
      { Header: "name", accessor: "name", align: "left" },
      { Header: "price", accessor: "price", align: "left", width: "15%" },
      { Header: "courses List", accessor: "courseslist", width: "30%", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center", width: "30%" },
    ],

    rws: tableData.map((item, index) => {
      return {
        no: index + 1,
        packageImage: <PackageImage packageImage={item.packageImage} />,
        name: <Author name={item.packageName} />,
        courseslist: <Job courses={item.courses} />,
        price: item.price,
        status: (
          <MDBox ml={-1}>
            <MDBadge
              badgeContent={item.isActive ? "active" : "Not active"}
              color={item.isActive ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {new Date(item?.updated_at).toLocaleDateString()}
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => editPackage(item)}
            >
              Edit
            </MDTypography>
            &nbsp; &nbsp;
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => deletePackage(item)}
            >
              Delete
            </MDTypography>
          </MDBox>
        ),
      };
    }),
  };
}
