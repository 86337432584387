/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================


 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { PropagateLoader } from "react-spinners";

function Loader() {
  return (
    <div className="loader-backdrop">
      <div className="loader">
        <PropagateLoader color="#36d7b7" />
      </div>
    </div>
  );
}

export default Loader;
