/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Pdf from "react-to-pdf";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import "./table.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import FormField from "layouts/applications/wizard/components/FormField";

// import MDButton from "components/MDButton";

// Data
import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  // DialogTitle,
  Divider,
  Icon,
} from "@mui/material";
// import FormField from "layouts/applications/wizard/components/FormField";
// import PromptModal from "components/PromptModal";
import MDDatePicker from "components/MDDatePicker";
import Header from "react-images-viewer/lib/components/Header";
import OrderInfo from "layouts/ecommerce/orders/order-details/components/OrderInfo";
import PaymentDetails from "layouts/ecommerce/orders/order-details/components/PaymentDetails";
import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";
import OrderSummary from "layouts/ecommerce/orders/order-details/components/OrderSummary";
import TrackOrder from "layouts/ecommerce/orders/order-details/components/TrackOrder";
import authorsTableData from "./data/authorsTableData";

// import { DesktopDatePicker } from "@mui/x-date-pickers";
// import { TextField } from "@mui/material";
// import projectsTableData from "layouts/tables/data/projectsTableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: 700,
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

// const sampleCategory = [
//   {
//     id: 1,
//     name: "Parent Category 1",
//   },
//   { id: 2, name: "Parent Category 2" },
// ];

let tableData = null;
let orderStateData = null;
let sDate;
let eDate;
let sOrderId;
let sName;
function Orders() {
  const ref = React.createRef();
  const hyloUrl = `${process.env.REACT_APP_BASE_URL}/hylo`;
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/courses`;
  const orderUrl = `${process.env.REACT_APP_BASE_URL}/orders`;
  const razorPayUrl = `${process.env.REACT_APP_BASE_URL}/razorpay`;
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [remarks, setRemarks] = useState("");
  const [orderId, setOrderId] = useState(0);
  const [orderState, setOrderState] = useState([]);
  const [currentOrderStatus, setCurrentOrderStatus] = useState("captured");
  const [newOrderStatus, setNewOrderStatus] = useState(0);

  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [startDate, setStartDate] = useState("");
  // eslint-disable-next-line no-shadow
  const [searchOrderId, setSearchOrderId] = useState("");
  const [searchName, setSearchName] = useState("");
  const [endDate, setEndDate] = useState("");
  const [orderHistory, setOrderHistory] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDialogClose = () => setDialogOpen(false);

  const setForm = (data) => {
    setOrderId(data?.id);
    setRemarks(data?.description);
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = async (order) => {
    // setModalActionTitle("UPDATE");

    if (tableData?.length > 0) {
      setSelectedOrder(order);
      let orerHistory;
      // eslint-disable-next-line eqeqeq
      if (order.candidate_id == order.customer_id) {
        orerHistory = await axios.get(`${hyloUrl}/payment-status/${order.order_id}`);
        if (orerHistory) {
          const orderObj = JSON.parse(orerHistory.data.data);
          orderObj.status =
            orderObj.status === "APPROVED" && orderObj.PaymentDetails.paymentStatus === "PAID"
              ? "captured"
              : "failed";

          setOrderHistory(orderObj);
        }
      } else {
        orerHistory = await axios.get(`${razorPayUrl}/fetch-order/${order.order_id}`);
        if (orerHistory) {
          setOrderHistory(orerHistory.data.data.items[0]);
        }
      }
      setForm(order);
      setDialogOpen(true);
    }
    setButtonTitle("UPDATE");
  };

  const getOrderStatus = (status) => orderStateData.filter((item) => item.title === status);

  const editItem = (order) => {
    // setModalActionTitle("UPDATE");
    if (tableData?.length > 0) {
      setForm(order);
      setOrderId(order.id);
    }
    const status = getOrderStatus(order.status ? order.status : "awaiting");
    setCurrentOrderStatus(status && status.length > 0 ? status[0].title : orderState[1].title);
    setButtonTitle("UPDATE");
    handleOpen();
  };

  const clearForm = () => {
    setRemarks("");
  };

  const resetFilters = () => {
    setStartDate("");
    setEndDate("");
    setSearchOrderId("");
    setSearchName("");
    // eslint-disable-next-line no-use-before-define
    fetchOrders(false);
  };

  const setTableData = (data) => {
    tableData = data;
    const { cols, rws } = authorsTableData(data, orderStateData, setitemIndex, editItem);
    setRows(rws);
    setColumns(cols);
    // resetFilters();
  };

  const fetchOrders = (filter = false) => {
    clearForm();
    let path;
    if (!filter) {
      path = "/orders/list";
    } else {
      path = `/orders/list?startDate=${sDate}&endDate=${eDate}&orderId=${sOrderId}&candidateName=${sName}`;
    }
    axios.get(`${baseUrl}${path}`).then(async (data) => {
      console.log(data);
      // await setTableData(data.resultMessage.data);
      if (data?.data?.resultMessage?.data) {
        setTableData(data.data.resultMessage.data);
      }
    });
  };

  const handleDateChange = (event, type) => {
    // const val = event[0].toISOString().slice(0, 10);
    const tempDate = event[0].toLocaleDateString().split("/");
    const val = `${tempDate[2]}-${tempDate[0]}-${tempDate[1]}`;
    if (type === "S") {
      setStartDate(val);
      sDate = val;
    } else if (type === "E") {
      setEndDate(val);
      eDate = val;
    }
    fetchOrders(true);
  };

  const handleNameSearch = (event) => {
    const { value } = event.target;
    setSearchName(value === "" ? undefined : value);
    sName = value === "" ? undefined : value;
    fetchOrders(true);
  };

  const handleOrderIdSearch = (event) => {
    const { value } = event.target;
    setSearchOrderId(value === "" ? undefined : value);
    sOrderId = value === "" ? undefined : value;
    fetchOrders(true);
  };

  const fetchOrderStatus = () => {
    const path = "/status";
    axios.get(`${orderUrl}${path}`).then(async (res) => {
      setOrderState(res.data.data);
      orderStateData = res.data.data;
      console.log(orderState);
      console.log(res.data.data);
    });
  };

  useEffect(() => {
    fetchOrderStatus();
    fetchOrders();
  }, []);

  const handleForm = (event, key) => {
    console.log(orderState);
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "REMARKS":
        if (value !== null) {
          setRemarks(value);
        }
        break;
      default:
        setRemarks(value);
        break;
    }
  };

  const generateAndDownloadReport = () => {
    axios({
      url: `${baseUrl}/orders/download?startDate=${sDate}&endDate=${eDate}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "Orders.xlsx");
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const performAction = async () => {
    const payload = {
      id: orderId,
      payment_status: newOrderStatus,
      remarks,
    };
    console.log(payload);
    const actionEndPoint = "/update";
    axios.put(`${orderUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      setOpen(false);
      resetFilters();
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="modal-window">
        {/* <PromptModal title={deleteWarning} /> */}

        <Dialog fullWidth="md" maxWidth="xl" open={dialogOpen} onClose={handleDialogClose}>
          <Pdf targetRef={ref} filename={`${selectedOrder.order_id}.pdf`}>
            {({ toPdf }) => (
              <MDBox style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button onClick={toPdf}>Generate PDF</Button>
              </MDBox>
            )}
          </Pdf>
          {/* <DialogTitle>Order Details</DialogTitle> */}
          <DialogContent style={{ width: "100%" }}>
            <MDBox my={6}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} lg={8}>
                  <Card>
                    <MDBox pt={2} px={2}>
                      <Header order={orderHistory} />
                    </MDBox>
                    <Divider />
                    <MDBox pt={1} pb={3} px={2} ref={ref}>
                      <MDBox mb={3}>
                        <OrderInfo order={selectedOrder} orderHistory={orderHistory} />
                      </MDBox>
                      <Divider />
                      <MDBox mt={3}>
                        <Grid container spacing={3}>
                          {/* <Grid item xs={12} md={6} lg={3}>
                            <TrackOrder order={selectedOrder} />
                          </Grid> */}
                          <Grid item xs={12} md={6} lg={5}>
                            <PaymentDetails orderHistory={orderHistory} />
                            <MDBox mt={3}>
                              <BillingInformation order={selectedOrder} />
                            </MDBox>
                          </Grid>
                          <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
                            <OrderSummary order={selectedOrder} />
                          </Grid>
                        </Grid>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Grid>
              </Grid>
            </MDBox>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            {orderState.length > 0 ? (
              <MDBox mb={2}>
                <MDInput
                  type="type"
                  label="Current Status"
                  value={
                    orderState.filter((item) => item.title === currentOrderStatus)[0].description
                  }
                  fullWidth
                  disabled
                />
              </MDBox>
            ) : null}
            {orderState.length > 0 ? (
              <MDBox mb={2}>
                <Autocomplete
                  defaultValue={orderState.filter((item) => item.title === currentOrderStatus)[0]}
                  // defaultValue={currentOrderStatus}
                  options={orderState}
                  getOptionLabel={(option) => option.description}
                  onChange={(e, v) => setNewOrderStatus(v.title)}
                  renderInput={(params) => (
                    <FormField {...params} label="New Status" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            ) : null}

            <MDBox mb={2}>
              <MDInput
                label="Remarks"
                multiline
                value={remarks}
                rows={5}
                fullWidth
                onChange={(e) => handleForm(e, "REMARKS")}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="orders-button-wrap">
                <MDBox ml={2} mt={2} width={260}>
                  <MDInput
                    type="type"
                    label="Search by Candidate Name"
                    fullWidth
                    value={searchName}
                    onChange={(e) => handleNameSearch(e)}
                  />
                </MDBox>
                <MDBox ml={2} mt={2} width={260}>
                  <MDInput
                    type="type"
                    label="Search OrderId"
                    fullWidth
                    value={searchOrderId}
                    onChange={(e) => handleOrderIdSearch(e)}
                  />
                </MDBox>
                <MDBox ml={2} mt={2} width={250}>
                  <MDDatePicker
                    onChange={(e) => handleDateChange(e, "S")}
                    value={startDate}
                    input={{ placeholder: "Select Start Date" }}
                  />
                </MDBox>
                <MDBox ml={2} mt={2} width={250}>
                  <MDDatePicker
                    onChange={(e) => handleDateChange(e, "E")}
                    value={endDate}
                    input={{ placeholder: "Select End Date" }}
                  />
                </MDBox>
                <MDBox ml={2} mt={2} width={250}>
                  <MDButton color="dark" onClick={resetFilters}>
                    CLEAR
                  </MDButton>
                </MDBox>
                {startDate !== "" && endDate !== "" ? (
                  <MDBox ml={2} mt={2} width={250}>
                    <MDButton color="dark" onClick={generateAndDownloadReport}>
                      <Icon>download</Icon>
                    </MDButton>
                  </MDBox>
                ) : null}
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Orders
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage
                    showTotalEntries
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Orders;
