/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {
  Autocomplete,
  Box,
  Card,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import FormField from "layouts/applications/wizard/components/FormField";
import { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import AWS from "aws-sdk";

AWS.config.update({
  accessKeyId: "AKIAU6GDZZFUMBPCAAQI",
  secretAccessKey: "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",
  region: "ap-south-1",
  signatureVersion: "v4",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function ImageGallery() {
  const s3 = new AWS.S3();
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/gallery`;
  // const [title, setTitle] = useState("");
  const [galleryCategory, setGalleryCategory] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [file, setFile] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleDeleteClose = () => setDeleteOpen(false);

  const fetchGalleryCategory = () => {
    fetch(`${baseUrl}/category`)
      .then((results) => results.json())
      .then(async (data) => {
        // await setTableData(data.resultMessage.data);
        if (data) {
          setGalleryCategory(data.data);
        }
      });
  };

  const fetchImageGallery = () => {
    fetch(baseUrl)
      .then((results) => results.json())
      .then(async (data) => {
        if (data) {
          console.log(data.data);
          setGallery(data.data);
        }
      });
  };

  useEffect(() => {
    fetchImageGallery();
    fetchGalleryCategory();
  }, []);

  const handleFileChange = async (e) => {
    setFile(e.target.files[0]);
  };

  const performAction = async () => {
    let imgUrl;
    if (file) {
      const params = {
        Bucket: "eurocourse-images/awards",
        Key: `${Date.now()}.${file.name}`,
        Body: file,
      };
      const { Location } = await s3.upload(params).promise();
      imgUrl = Location;
      setImageUrl(Location);
    }

    const payload = {
      category_id: categoryId,
      image_url: imgUrl,
    };

    console.log(imageUrl);

    let actionEndPoint = "";

    actionEndPoint = `/add`;
    axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      fetchImageGallery();
      setFile(null);
    });
  };

  const getCategoryName = (id) => {
    const category = galleryCategory.filter((item) => item.id === id);
    return category[0]?.name;
  };

  const deleteImage = () => {
    axios.delete(`${baseUrl}/delete/${selectedId}`).then((response) => {
      console.log(response);
      setDeleteOpen(false);
      fetchImageGallery();
      setFile(null);
    });
  };

  const initiateDelete = (id) => {
    setSelectedId(id);
    setDeleteOpen(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={deleteOpen}
        onClose={handleDeleteClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        {/* <Fade in={open}> */}
        <Box sx={style}>
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Do you want to delete the selected Image ?
          </Typography>
          <div className="prompt-button-box">
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={() => deleteImage()}>
                DELETE
              </MDButton>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => handleDeleteClose()}
              >
                CANCEL
              </MDButton>
            </MDBox>
          </div>
        </Box>
      </Modal>
      <Card sx={{ height: "100%", margin: "20px 0px", padding: "10px 40px 40px 10px" }}>
        <MDBox>
          <MDTypography variant="h5" fontWeight="bold">
            About
          </MDTypography>
          <MDBox mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue={galleryCategory[0]}
                  options={galleryCategory}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, v) => setCategoryId(v.id)}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Gallery Category"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDBox mt={2} mb={2}>
                  <label htmlFor="course-image">
                    Choose Course image:
                    <input type="file" name="course-image" onChange={handleFileChange} />
                  </label>
                </MDBox>
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDBox mt={2} mb={2}>
                  <MDButton variant="gradient" color="info" onClick={() => performAction()}>
                    ADD
                  </MDButton>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px" }}>
        <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
          {gallery.map((item) => (
            <ImageListItem key={item.id}>
              <img
                src={`${item.image_url}?w=164&h=164&fit=crop&auto=format`}
                srcSet={`${item.image_url}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                title={getCategoryName(item.category_id)}
                subtitle={item.name}
                actionIcon={
                  <IconButton
                    sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                    aria-label={`info about ${item.name}`}
                    onClick={() => initiateDelete(item.id)}
                  >
                    <Delete />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Card>
    </DashboardLayout>
  );
}

export default ImageGallery;
