/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
// import Tooltip from "@mui/material/Tooltip";
// import Icon from "@mui/material/Icon";
// import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDBadgeDot from "components/MDBadgeDot";
// import MDButton from "components/MDButton";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
// import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
// import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
// import SalesTable from "examples/Tables/SalesTable";
// import DataTable from "examples/Tables/DataTable";

// Sales dashboard components
// import ChannelsChart from "layouts/dashboards/sales/components/ChannelsChart";

// Data
// import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import CoursesTable from "examples/Tables/CoursesTable";
import {
  // Card,
  Tab,
  Tabs,
} from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import axios from "axios";
import MDTypography from "components/MDTypography";
// import channelChartData from "./components/ChannelsChart/data";
import defaultLineChartData from "./data/defaultLineChartData";
// import horizontalBarChartData from "layouts/dashboards/sales/data/horizontalBarChartData";
// import salesTableData from "layouts/dashboards/sales/data/salesTableData";
// import dataTableData from "layouts/dashboards/sales/data/dataTableData";
// import reportsBarChartData from "../analytics/data/reportsBarChartData";

function Sales() {
  // DefaultStatisticsCard state for the dropdown tabValue
  const [salesDropdownValue, setSalesDropdownValue] = useState("1 Jan - 20 Jan");
  const [customersDropdownValue, setCustomersDropdownValue] = useState("1 Jan - 20 Jan");
  // const [revenueDropdownValue, setRevenueDropdownValue] = useState("1 Jan - 20 Jan");

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  // const [revenueDropdown, setRevenueDropdown] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  // const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
  // const closeRevenueDropdown = ({ currentTarget }) => {
  //   setRevenueDropdown(null);
  //   setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  // };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  // Analytics data
  let sDate;
  let eDate;
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/analytics`;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [topCourses, setTopCourses] = useState([]);
  const [grossSalesData, setGrossSalesData] = useState({});
  const [totalOrdersData, setTotalOrdersData] = useState({});
  const [numOfCoursesData, setNumOfCoursesData] = useState({});
  const [registrationCount, setRegistrationCount] = useState({});
  const [courseCount, setCourseCount] = useState({});
  const [batchCount, setBatchCount] = useState({});
  const [isDatePopulated, setIsDatePopulated] = useState(false);

  // TAB PROPERTIES AND FUNCTIONS
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [tabValue, setTabValue] = useState(0);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  // TAB PROPERTIES AND FUNCTIONS

  // fetch data for different bar charts
  const fetchAnalytics = async () => {
    // clearForm();

    // if (!filter) {
    //   queryParam = "/analytics";
    // } else {
    //   queryParam = `/analytics/list?startDate=${sDate}&endDate=${eDate}`;
    // }

    let queryParam;

    switch (tabValue) {
      case 0:
        queryParam = "?timeFrame=year";
        break;
      case 1:
        queryParam = "?timeFrame=lastMonth";
        break;
      case 2:
        queryParam = "?timeFrame=thisMonth";
        break;
      case 3:
        queryParam = `?startDate=${sDate}&endDate=${eDate}`;
        break;
      default:
        queryParam = "invalid";
        break;
    }

    if ((tabValue === 3 && sDate && eDate) || tabValue !== 3) {
      const analyticsData = await axios.get(`${baseUrl}/getAnalyticsData${queryParam}`);
      setGrossSalesData(analyticsData.data.data.grossSalesData);

      setTotalOrdersData(analyticsData.data.data.totalOrdersData);

      setNumOfCoursesData(analyticsData.data.data.coursesData);
    }

    console.log("fetched");
  };

  // Initial formatting of date
  function setDateFormat(type, state) {
    const tempDate = state.toLocaleDateString().split("/");
    const val = `${tempDate[2]}-${tempDate[0]}-${tempDate[1]}`;
    if (type === "S") {
      setStartDate(val);
      sDate = val;
    } else if (type === "E") {
      setEndDate(val);
      eDate = val;
    }
  }

  // convert date to mysql appropriate format
  const handleDateChange = (event, type) => {
    const tempDate = event[0].toLocaleDateString().split("/");
    const val = `${tempDate[2]}-${tempDate[0]}-${tempDate[1]}`;
    if (type === "S") {
      setStartDate(val);
      sDate = val;
      console.log(sDate);
      console.log(eDate);
    } else if (type === "E") {
      setEndDate(val);
      eDate = val;
    }

    fetchAnalytics();
  };

  // data for the cards at the top
  useEffect(() => {
    async function fetchData() {
      try {
        const registration = await axios.get(`${baseUrl}/getRegistrations`);
        setRegistrationCount(registration.data.data);

        const courses = await axios.get(`${baseUrl}/getCourseSummary`);
        setCourseCount(courses.data.data);

        const batches = await axios.get(`${baseUrl}/getActiveBatches`);
        setBatchCount(batches.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, []);

  // executes whenever tabs are changed
  useEffect(() => {
    if (tabValue === 3 && !isDatePopulated) {
      setDateFormat("S", startDate);
      setDateFormat("E", endDate);
      setIsDatePopulated(true);
    }

    fetchAnalytics();
  }, [tabValue]);

  // data for the top courses table at the bottom
  useEffect(() => {
    axios
      .get(`${baseUrl}/getTopCourses`)
      .then((res) => {
        setTopCourses(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Registration"
                // count="$230,220"
                count={registrationCount.count}
                percentage={{
                  color: registrationCount.lastMonthDiff > 0 ? "success" : "error",
                  value:
                    registrationCount.lastMonthDiff > 0
                      ? `+${registrationCount.lastMonthDiff}%`
                      : `${registrationCount.lastMonthDiff}%`,
                  label: "since last month",
                }}
                dropdown={{
                  action: openSalesDropdown,
                  menu: renderMenu(salesDropdown, closeSalesDropdown),
                  value: salesDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="Courses"
                count={courseCount.count}
                percentage={{
                  color: courseCount.lastMonthDiff > 0 ? "success" : "error",
                  value:
                    courseCount.lastMonthDiff > 0
                      ? `+${courseCount.lastMonthDiff}%`
                      : `${courseCount.lastMonthDiff}%`,
                  label: "since last month",
                }}
                dropdown={{
                  action: openCustomersDropdown,
                  menu: renderMenu(customersDropdown, closeCustomersDropdown),
                  value: customersDropdownValue,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DefaultStatisticsCard
                title="active batches"
                count={batchCount.count}
                // percentage={{
                //   color: "secondary",
                //   value: "",
                //   label: "since last month",
                // }}
                // dropdown={{
                //   action: openRevenueDropdown,
                //   menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                //   value: revenueDropdownValue,
                // }}
              />
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} sm={12} lg={12}>
              <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Year" {...a11yProps(0)} />
                <Tab label="Last Month" {...a11yProps(1)} />
                <Tab label="This Month" {...a11yProps(2)} />
                <Tab label="Custom" {...a11yProps(3)} />
              </Tabs>
            </Grid>
            {tabValue === 3 && (
              <>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h6">Start Date</MDTypography>
                  <MDDatePicker
                    onChange={(e) => handleDateChange(e, "S")}
                    value={startDate}
                    input={{ placeholder: "Select Start Date" }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h6">End Date</MDTypography>
                  <MDDatePicker
                    onChange={(e) => handleDateChange(e, "E")}
                    value={endDate}
                    input={{ placeholder: "Select End Date" }}
                  />
                </Grid>
                {/* <Grid item xs={12} sm={3}>
                  <MDButton color="dark" onClick={fetchAnalytics}>
                    Submit
                  </MDButton>
                </Grid> */}
              </>
            )}
            {/* BAR CHARTS */}
            <Grid item xs={12} sm={12} lg={6}>
              {/* <ChannelsChart /> */}
              <VerticalBarChart
                title="Gross Sales"
                // description={
                //   <MDBox display="flex" justifyContent="space-between">
                //     <MDBox display="flex" ml={-1}>
                //       <MDBadgeDot color="info" size="sm" badgeContent="Courses" />
                //       <MDBadgeDot color="dark" size="sm" badgeContent="Public ads" />
                //     </MDBox>
                //     <MDBox mt={-4} mr={-1} position="absolute" right="1.5rem">
                //       <Tooltip title="See which ads perform better" placement="left" arrow>
                //         <MDButton
                //           variant="outlined"
                //           color="secondary"
                //           size="small"
                //           circular
                //           iconOnly
                //         >
                //           <Icon>priority_high</Icon>
                //         </MDButton>
                //       </Tooltip>
                //     </MDBox>
                //   </MDBox>
                // }
                chart={grossSalesData}
              />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <VerticalBarChart title="Total Orders" chart={totalOrdersData} />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <VerticalBarChart title="Number of Courses Purchased" chart={numOfCoursesData} />
            </Grid>
            <Grid item xs={12} sm={12} lg={6}>
              <VerticalBarChart title="Refunded Orders" chart={defaultLineChartData} />
            </Grid>
            {/* BAR CHARTS */}
          </Grid>
        </MDBox>
        <MDBox mb={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} lg={8}>
              <HorizontalBarChart title="Sales by age" chart={horizontalBarChartData} />
            </Grid> */}
            <Grid item xs={12}>
              {/* <SalesTable title="Top Selling Courses" rows={salesTableData} /> */}
              <CoursesTable title="Top Selling Courses" data={topCourses} />
            </Grid>
          </Grid>
        </MDBox>
        {/* <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3} px={3}>
                <MDTypography variant="h6" fontWeight="medium">
                  Top Selling Products
                </MDTypography>
              </MDBox>
              <MDBox py={1}>
                <DataTable
                  table={dataTableData}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  isSorted={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Sales;
