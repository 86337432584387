/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// react-router-dom components
// import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
// import MuiLink from "@mui/material/Link";
import validator from "validator";

// @mui icons
// import FacebookIcon from "@mui/icons-material/Facebook";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.png";
import axios from "axios";

function Basic(props) {
  const loginRef = useRef();
  const { mode } = props;
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`;

  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const processLogin = () => {
    const actionEndPoint = "/admin/login";
    const payload = { email, password };
    axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      if (response.data && response.data.statusCode === 201) {
        const userObj = {
          userName: response.data.data.full_name,
          email: response.data.data.email,
          token: response.data.data.token,
        };
        localStorage.setItem("userDetails", JSON.stringify(userObj));
        window.location = "/dashboards";
      }
    });
  };

  const handleInput = (type, event) => {
    if (type === "E") {
      setEmail(event.target.value);
    } else if (type === "P") {
      setPassword(event.target.value);
    }
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      processLogin();
    }
  };

  const login = () => {
    console.log(email, password);
    if (validator.isEmail(email)) {
      setEmailError("");
      processLogin();
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  useEffect(() => {
    if (mode === "LOGOUT") {
      localStorage.removeItem("userDetails");
    }
    console.log(props);
  });

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <Grid container justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
              <p>EuroTech</p>
              <h6>Admin Portal</h6>
            </MDTypography>
          </Grid>
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                onKeyUp={(event) => handleInput("E", event)}
              />
              {emailError.length > 0 ? (
                <MDTypography color="error" fontSize="14px">
                  {emailError}
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                onKeyUp={(event) => handleInput("P", event)}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Remember me
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                ref={loginRef}
                onClick={() => {
                  login();
                }}
              >
                sign in
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center"></MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;

Basic.propTypes = {
  mode: PropTypes.node.isRequired,
};
