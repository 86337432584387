export default class UtilityServices {
  static formatDate(time, format) {
    let returnDate;
    const today = new Date(time);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1;
    let dd = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    switch (format) {
      case "DD/MM/YYYY":
        returnDate = `${dd}/${mm}/${yyyy}`;
        break;
      case "DD-MM-YYYY":
        returnDate = `${dd}-${mm}-${yyyy}`;
        break;
      case "MM/DD/YYYY":
        returnDate = `${mm}/${dd}/${yyyy}`;
        break;
      default:
        returnDate = `${dd}/${mm}/${yyyy}`;
        break;
    }

    return returnDate;
  }
}
