/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
// import moment from "moment";
import moment from "moment-timezone";
import "./table.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";

// Data
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Alert, Autocomplete, Dialog, Snackbar } from "@mui/material";
import MDDatePicker from "components/MDDatePicker";
import axios from "axios";
import FormField from "layouts/applications/wizard/components/FormField";
import authorsTableData from "./data/authorsTableData";

// import { DesktopDatePicker } from "@mui/x-date-pickers";
// import { TextField } from "@mui/material";
// import projectsTableData from "layouts/tables/data/projectsTableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: 700,
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

let tableData = null;
let sDate;
let sText;
// eslint-disable-next-line no-unused-vars
function Batches() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [open, setOpen] = useState(false);
  // const [action, setAction] = useState("ADD");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [name, setName] = useState("");
  const [courses, setCourses] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [batches, setBatches] = useState([]);
  const [courseId, setCourseId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateError, setDateError] = useState(false);
  const [rac, setRac] = useState("0");
  const [seat, setSeat] = useState("0");
  const [statusId, setStatusId] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [statusName, setStatusName] = useState("");
  const [batchStatusList, setBatchStatusListe] = useState("");
  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [selectedBatch, setSelectedBatch] = useState("");
  const [alertText, setAlertText] = useState("Success");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [isAdd, setIsAdd] = useState(true);
  const [filterStartDate, setFilterStartDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchKeyWord, setSearchKeyWord] = useState(" ");
  // eslint-disable-next-line no-unused-vars
  const [multiBatch, setMultiBatch] = useState(false);
  const [openMulti, setOpenMulti] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [multiBatchItem, setMultiBatchItem] = useState([]);

  const clearMultiBatchItem = () => {
    setMultiBatchItem([
      {
        statusId: 1,
        startDate: new Date(),
        rac: 0,
        seat: 0,
      },
    ]);
  };

  const handleDialogClose = () => {
    setOpenMulti(false);
    clearMultiBatchItem();
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dateCheck = (start, end) => {
    if (moment(start) > moment(end)) {
      setDateError(true);
    } else {
      setDateError(false);
    }
    setDateError(false);
  };

  const setForm = async (data) => {
    setName(data ? data?.title : "");
    setCourseId(data ? data?.course_id : courses[0].id);
    setStartDate(data ? data?.start_date : "");
    setEndDate(data ? data?.end_date : "");
    // setEndDate(data ? data?.updated_at.split("T")[0].replaceAll("-", "/") : "");
    dateCheck(data?.start_date, data?.end_date);
    setSeat(data ? data?.seats : "0");
    setStatusId(data ? data?.status_id : "");
    setStatusName(data ? data?.status : "");
    setRac(data ? data?.rac : "0");
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  // eslint-disable-next-line no-unused-vars
  const handleBatchStatusChange = (value) => {
    setStatusId(value);
  };

  const setitemIndex = (batch) => {
    setIsAdd(false);
    setSelectedBatch(batch);
    if (tableData?.length > 0) {
      setForm(batch);
    }
    setButtonTitle("UPDATE");
    handleOpen();
  };

  const deleteItem = (batch) => {
    setSelectedBatch(batch);
    if (tableData?.length > 0) {
      setForm(batch);
    }
    setButtonTitle("DELETE");
    handleOpen();
  };

  const handleCourseSelect = (value) => {
    setCourseId(value);
  };

  const fetchCourses = () => {
    axios.get(`${baseUrl}/courses`).then(async (data) => {
      // fetch(`${baseUrl}/courses`)
      //   .then((results) => results.json())
      //   .then(async (data) => {
      // await setTableData(data.resultMessage.data);
      setCourses(data.data.resultMessage.data);
    });
  };

  const setTableData = (data) => {
    const { cols, rws } = authorsTableData(data, setitemIndex, deleteItem);
    setRows(rws);
    setColumns(cols);
  };

  const applyFIlter = (searchText) => {
    setSearchKeyWord(searchText);
    sText = searchText;
    // eslint-disable-next-line no-use-before-define
    fetchBatches(true);
  };

  const fetchBatches = (filter = false) => {
    let path;
    if (!filter) {
      path = "/batches";
    } else {
      path = `/batches?startDate=${sDate}&searchText=${encodeURIComponent(sText)}`;
    }
    axios.get(`${baseUrl}${path}`).then(async (data) => {
      // await setTableData(data.resultMessage.data);
      setBatches(data.data.resultMessage.data);
      // setCategoryList(data?.resultMessage?.data);
      tableData = data.data.resultMessage.data;
      setTableData(data.data.resultMessage.data);
    });
  };

  const handleFilterDateChange = (event) => {
    // const val = event[0].toISOString().slice(0, 10);
    const val = moment(event[0].toISOString()).format("YYYY-MM-DD");
    sDate = val;
    fetchBatches(true);
  };

  const resetFilters = () => {
    setFilterStartDate("");
    fetchBatches(false);
  };

  const fetchBatchStatus = () => {
    axios.get(`${baseUrl}/batches/status`).then(async (data) => {
      // fetch(`${baseUrl}/batches/status`)
      //   .then((results) => results.json())
      //   // eslint-disable-next-line no-unused-vars
      //   .then(async (data) => {
      // await setTableData(data.resultMessage.data);
      setBatchStatusListe(data.data.data);
      fetchBatches();
    });
  };
  const initializeMultiBatchRaw = () => {
    setMultiBatchItem([
      {
        statusId: 1,
        startDate: new Date(),
        rac: 0,
        seat: 0,
      },
    ]);
  };

  useEffect(() => {
    initializeMultiBatchRaw();
    fetchCourses();
    fetchBatchStatus();
  }, []);

  const addBatches = () => {
    setIsAdd(true);
    setCourseId(courses[0].id);
    setForm(null);
    setModalActionTitle("ADD");
    setStatusId(batchStatusList[0].id);
    handleOpen();
  };

  const addMultiBatch = () => {
    setCourseId(courses[0].id);
    setOpenMulti(true);
  };

  const handleDateChange = (event, key) => {
    if (event && event.length > 0) {
      if (key === "SDATE") {
        setStartDate(
          sDate
            ? moment(event[0].toISOString()).add(1, "days").format("YYYY-MM-DD")
            : moment(event[0].toISOString()).format("YYYY-MM-DD")
        );
      } else if (event.length > 0) {
        setEndDate(
          sDate
            ? moment(event[0].toISOString()).add(1, "days").format("YYYY-MM-DD")
            : moment(event[0].toISOString()).format("YYYY-MM-DD")
        );
        dateCheck(startDate, event[0]);
      } else {
        setEndDate(event);
        dateCheck(startDate, event);
      }
    }
  };

  const handleMultiDateChange = (event, index) => {
    const val = multiBatchItem;
    // eslint-disable-next-line prefer-destructuring
    // val[index].startDate = moment(event[0].toISOString()).add(1, "days").format("YYYY-MM-DD");
    val[index].startDate = moment(event[0].toISOString()).format("YYYY-MM-DD");
    setMultiBatchItem(val);
  };

  const handleMultiBatchStatusChange = (value, index) => {
    const val = multiBatchItem;
    val[index].statusId = value;
    setMultiBatchItem(val);
  };
  const handleMultiRAC = (event, index) => {
    const { value } = event.target;
    const val = multiBatchItem;
    val[index].rac = value;
    setMultiBatchItem(val);
  };
  const handleMultiSeat = (event, index) => {
    const { value } = event.target;
    const val = multiBatchItem;
    val[index].seat = value;
    setMultiBatchItem(val);
  };

  const addMultiItem = () => {
    console.log("multi");
    const newData = [
      ...multiBatchItem,
      {
        statusId: 1,
        startDate: new Date(),
        rac: 0,
        seat: 0,
      },
    ];
    setMultiBatchItem(newData);
  };

  const handleForm = (event, key) => {
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "NAME":
        setName(value);
        break;
      case "CID":
        setCourseId(value);
        break;
      case "SDATE":
        if (value) {
          setStartDate(value);
        }
        break;
      case "EDATE":
        if (value) {
          setEndDate(value);
        }
        break;
      case "RAC":
        setRac(value);
        break;
      case "SEAT":
        setSeat(value);
        break;
      default:
        console.log(event);
        break;
    }
  };

  // const handleFileChange = (e) => {
  //   const img = {
  //     preview: URL.createObjectURL(e.target.files[0]),
  //     data: e.target.files[0],
  //   };
  //   setImage(img);
  //   console.log(image);
  // };
  const refreshGrid = () => {
    setOpen(false);
    fetchCourses();
    fetchBatches();
  };

  const performAction = async (action = "UPDATE") => {
    const payload = {
      title: name,
      course_id: courseId,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      rac,
      status: statusId,
      seats: seat,
      available_seats: parseInt(seat, 10) + parseInt(rac, 10),
    };
    let actionEndPoint = "/batches";
    if (action === "ADD") {
      actionEndPoint += "/add";
      axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        setAlertType("success");
        setAlertText(response.data.resultMessage.msgs);
        setShowAlert(true);
        refreshGrid();
      });
      console.log(action, payload);
    } else if (action === "UPDATE") {
      console.log(action, payload);
      actionEndPoint += `/update/${selectedBatch.id}`;
      axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        setAlertType("success");
        setAlertText(response.data.resultMessage.msgs);
        setShowAlert(true);
        refreshGrid();
      });
    } else if (action === "DELETE") {
      actionEndPoint += `/delete/${selectedBatch.id}`;
      axios.delete(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        if (response.data.resultCode === "201") {
          setAlertType("error");
        } else {
          setAlertType("success");
        }
        setAlertText(response.data.resultMessage.msg);
        setShowAlert(true);
        refreshGrid();
      });
    }
  };

  const performMultiBatchAction = () => {
    console.log(multiBatchItem);
    setOpenMulti(false);
    const payload = {
      title: name,
      course_id: courseId,
      bulkData: multiBatchItem,
    };
    const actionEndPoint = "/batches/bulkAdd";
    axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      setAlertType("success");
      setAlertText(response.data.resultMessage.msgs);
      setShowAlert(true);
      initializeMultiBatchRaw();
      refreshGrid();
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="modal-window">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          open={showAlert}
          message={alertText}
          severity={alertType}
          onClose={() => setShowAlert(false)}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity={alertType}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>
        <Modal
          width={900}
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mb={2}>
              <MDInput
                type="type"
                value={name}
                label="Title"
                fullWidth
                onChange={(e) => handleForm(e, "NAME")}
              />
            </MDBox>
            <MDBox mb={2}>
              {courses && courses.length > 0 ? (
                <Autocomplete
                  defaultValue={
                    modalActionTitle === "ADD"
                      ? courses[0]
                      : courses.filter((item) => item.id === selectedBatch.course_id)[0]
                  }
                  options={courses}
                  getOptionLabel={(option) => option.title}
                  onChange={(e, v) => handleCourseSelect(v.id)}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Choose Course"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              {isAdd ? (
                <MDDatePicker
                  options={{ minDate: new Date().toISOString() }}
                  // value={startDate}
                  onChange={(e) => handleDateChange(e, "SDATE")}
                  input={{ placeholder: "Course Date From" }}
                />
              ) : (
                <MDDatePicker
                  value={startDate}
                  onChange={(e) => handleDateChange(e, "SDATE")}
                  input={{ placeholder: "Course Date From" }}
                />
              )}
            </MDBox>
            <MDBox mb={2}>
              {isAdd ? (
                <MDDatePicker
                  options={{ minDate: new Date().toISOString() }}
                  value={endDate}
                  onChange={(e) => handleDateChange(e, "EDATE")}
                  input={{ placeholder: "Course Date To" }}
                />
              ) : (
                <MDDatePicker
                  value={endDate}
                  onChange={(e) => handleDateChange(e, "EDATE")}
                  input={{ placeholder: "Course Date To" }}
                />
              )}

              <br />
              {dateError ? (
                <MDTypography variant="caption" color="error">
                  End Date cannot be before Start Date
                </MDTypography>
              ) : null}
            </MDBox>
            <MDBox mb={2}>
              <Autocomplete
                defaultValue={
                  modalActionTitle === "ADD"
                    ? batchStatusList[0]
                    : batchStatusList.filter((item) => item.status === selectedBatch.status)[0]
                }
                options={batchStatusList}
                getOptionLabel={(option) => option.status}
                onChange={(e, v) => handleBatchStatusChange(v.id)}
                renderInput={(params) => (
                  <FormField {...params} label="Choose Status" InputLabelProps={{ shrink: true }} />
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="type"
                label="RAC"
                value={rac}
                fullWidth
                onChange={(e) => handleForm(e, "RAC")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="type"
                value={seat}
                label="Seat"
                fullWidth
                onChange={(e) => handleForm(e, "SEAT")}
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
        <Dialog maxWidth="xl" fullWidth="true" open={openMulti} onClose={handleDialogClose}>
          <Box p={5}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mb={2}>
              <MDInput
                type="type"
                value={name}
                label="Title"
                fullWidth
                onChange={(e) => handleForm(e, "NAME")}
              />
            </MDBox>
            <MDBox mb={2}>
              <Autocomplete
                defaultValue={
                  modalActionTitle === "ADD"
                    ? courses[0]
                    : courses.filter((item) => item.id === selectedBatch.course_id)[0]
                }
                options={courses}
                getOptionLabel={(option) => option.title}
                onChange={(e, v) => handleCourseSelect(v.id)}
                renderInput={(params) => (
                  <FormField {...params} label="Choose Course" InputLabelProps={{ shrink: true }} />
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <div className="multi-table">
                <div className="table-action">
                  <MDButton variant="gradient" color="dark" onClick={addMultiItem}>
                    ADD +
                  </MDButton>
                </div>
                {multiBatchItem &&
                  multiBatchItem.length > 0 &&
                  // eslint-disable-next-line array-callback-return, no-unused-vars
                  multiBatchItem.map((multiItem, index) => (
                    <div className="table-items" key={index}>
                      <MDBox mb={2}>
                        <MDDatePicker
                          options={{ minDate: new Date().toISOString() }}
                          value={startDate}
                          onChange={(e) => handleMultiDateChange(e, index)}
                          input={{ placeholder: "Course Date From" }}
                        />
                      </MDBox>
                      <MDBox mb={2} width={280}>
                        <Autocomplete
                          defaultValue={batchStatusList[0]}
                          options={batchStatusList}
                          getOptionLabel={(option) => option.status}
                          onChange={(e, v) => handleMultiBatchStatusChange(v.id, index)}
                          renderInput={(params) => (
                            <FormField
                              {...params}
                              label="Choose Status"
                              InputLabelProps={{ shrink: true }}
                            />
                          )}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="type"
                          label="RAC"
                          fullWidth
                          onChange={(e) => handleMultiRAC(e, index)}
                        />
                      </MDBox>
                      <MDBox mb={2}>
                        <MDInput
                          type="type"
                          label="Seat"
                          fullWidth
                          onChange={(e) => handleMultiSeat(e, index)}
                        />
                      </MDBox>
                      {/* <MDBox mb={2}>
                        <MDButton variant="gradient" color="error">
                          X
                        </MDButton>
                      </MDBox> */}
                    </div>
                  ))}
                <MDBox mb={2}>
                  <div className="prompt-button-box">
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={() => performMultiBatchAction(modalActionTitle)}
                      >
                        SUBMIT
                      </MDButton>
                    </MDBox>
                    <MDBox mt={4} mb={1}>
                      <MDButton
                        variant="outlined"
                        color="info"
                        fullWidth
                        onClick={() => handleDialogClose()}
                      >
                        CANCEL
                      </MDButton>
                    </MDBox>
                  </div>
                </MDBox>
              </div>
            </MDBox>
          </Box>
        </Dialog>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap">
                <MDBox ml={2} mt={2} width={300}>
                  <Autocomplete
                    defaultValue={courses[0]}
                    options={courses}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, v, reason) => {
                      // eslint-disable-next-line no-unused-expressions
                      reason === "clear" ? applyFIlter("", "C") : applyFIlter(v.title, "C");
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Filter by Course"
                      />
                    )}
                  />
                </MDBox>
                <MDBox ml={2} mt={2} width={250}>
                  <MDDatePicker
                    onChange={(e) => handleFilterDateChange(e)}
                    value={filterStartDate}
                    input={{ placeholder: "Select Start Date" }}
                  />
                </MDBox>
                <MDBox ml={2} mt={2} width={150}>
                  <MDButton color="dark" onClick={resetFilters}>
                    RESET FILTER
                  </MDButton>
                </MDBox>
                <MDBox ml={2} mt={2} width={150}>
                  <MDButton color="info" onClick={addBatches}>
                    Add Batches
                  </MDButton>
                  {/* <button className="btn" type="button" onClick={addBatches}>
                    Add Batches
                  </button> */}
                </MDBox>
                <MDBox ml={2} mt={2} width={260}>
                  <MDButton color="info" onClick={addMultiBatch}>
                    Add Multi Batches
                  </MDButton>
                  {/* <button className="btn" type="button" onClick={addBatches}>
                    Add Batches
                  </button> */}
                </MDBox>
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Batches
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage
                    showTotalEntries
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Batches;
