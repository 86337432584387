import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Grid, Paper, Typography, Box } from "@mui/material";

const GrievancePage = () => {
  const [grievanceList, setGrievanceList] = useState([]);
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/griverance/list`;

  const fetchGrievanceList = async () => {
    try {
      const response = await axios.get(baseUrl);
      if (response.data.status) {
        setGrievanceList(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching grievance list:", error);
    }
  };

  useEffect(() => {
    fetchGrievanceList();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="div" align="center">
              <Box sx={{ fontWeight: "bold", fontSize: "25px" }}>Grievance List</Box>
            </Typography>
          </Grid>
          {grievanceList.map((row) => (
            <Grid key={row.id} item xs={12}>
              <Paper sx={{ padding: "20px", height: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography variant="inherit">Date</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="inherit">
                      {new Date(row.created_at).toLocaleDateString("en-CA")}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="inherit">Name</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="inherit">
                      {row.anonymous_status ? "Anonymous" : row.name}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="inherit">Contact</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="inherit">
                      {row.anonymous_status ? "Anonymous" : row.contact}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="inherit">Email</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="inherit">
                      {row.anonymous_status ? "Anonymous" : row.email}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="inherit">Grievance</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="inherit">{row.grievance}</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default GrievancePage;
