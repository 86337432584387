/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Dialog, Divider } from "@mui/material";
import axios from "axios";

// import Typography from "@mui/material/Typography";
import "./table.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// import MDButton from "components/MDButton";

// Data
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import Header from "./components/Header";
import PlatformSettings from "./profile-overview/components/PlatformSettings";
import authorsTableData from "./data/authorsTableData";
import DocumentLists from "./components/DocumentLists";

let tableData = null;

function Students() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [openAssign, setOpenAssign] = useState(false);
  // const [action, setAction] = useState("ADD");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedDocuments, setSelectedDocuments] = useState({});

  const fetchCandidateCourse = (candidateId) => {
    axios.get(`${baseUrl}/courses/student/order/${candidateId}`).then((res) => {
      if (res && res.data && res.data.resultMessage && res.data.resultMessage.data) {
        setSelectedCourses(res.data.resultMessage.data);
      }
    });
  };
  const fetchCandidateDocuments = (candidateId) => {
    axios.get(`${baseUrl}/courses/student/documents/${candidateId}`).then((res) => {
      if (res && res.data && res.data.resultMessage && res.data.resultMessage.data) {
        setSelectedDocuments(res.data.resultMessage.data);
      }
    });
  };

  const handleViewOpen = () => {
    setOpenAssign(true);
  };

  const handleViewClose = () => setOpenAssign(false);

  const setForm = async (data) => {
    fetchCandidateCourse(data.id);
    fetchCandidateDocuments(data.id);
    console.log(data);
  };

  const setitemIndex = (student) => {
    if (tableData?.length > 0) {
      setForm(student);
      setSelectedCandidate(student);
    }
    handleViewOpen();
  };

  const fetchCandidates = async () => {
    axios.get(`${baseUrl}/candidate`).then((res) => {
      console.log(res);
      // await fetch(`${baseUrl}/candidate`)
      //   .then((results) => results.json())
      //   .then(async (response) => {
      tableData = res.data.data;
      const { cols, rws } = authorsTableData(res.data.data, setitemIndex);
      setRows(rws);
      setColumns(cols);
    });
  };
  useEffect(() => {
    fetchCandidates();
  }, []);

  const setTableData = (data) => {
    const { cols, rws } = authorsTableData(data, setitemIndex);
    console.log(rws);
    setRows(rws);
    setColumns(cols);
  };

  const searchStudents = (event) => {
    const searchText = event.target.value;
    if (searchText.length > 0) {
      const nameFilter = tableData.filter(
        (candidate) => candidate.full_name.toLowerCase().indexOf(searchText) > -1
      );
      const emailFilter = tableData.filter(
        (candidate) => candidate.email.toLowerCase().indexOf(searchText) > -1
      );
      console.log(nameFilter);
      if (nameFilter.length > 0) {
        setTableData(nameFilter);
      } else if (emailFilter.length > 0) {
        setTableData(emailFilter);
      }
    } else {
      setTableData(tableData);
    }
  };

  // const refreshGrid = () => {
  //   setOpenAssign(false);
  //   fetchCandidates();
  // };

  // const performAction = async () => {
  //   const payload = {
  //     id: candidateId,
  //     batch_id: selectedBatch,
  //   };
  //   let actionEndPoint = "/candidate";
  //   actionEndPoint += "/updateBatch";
  //   axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
  //     console.log(response);
  //     refreshGrid();
  //   });
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="modal-window">
        <Dialog fullWidth="md" maxWidth="xl" open={openAssign} onClose={handleViewClose}>
          <Header candidate={selectedCandidate} />
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={4}>
                <PlatformSettings courses={selectedCourses} />
              </Grid>
              <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                <ProfileInfoCard
                  title="profile information"
                  description={selectedCandidate.full_name ? selectedCandidate.full_name : ""}
                  info={{
                    fullName: selectedCandidate.full_name,
                    mobile: selectedCandidate.phone,
                    email: selectedCandidate.email,
                    DOB: selectedCandidate.date_of_birth,
                    kinName: selectedCandidate.name_of_next_of_kin,
                    kinNumber: selectedCandidate.contact_of_next_of_kin,
                    indos_number: selectedCandidate.indos_number,
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: "",
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: "",
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: "",
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} xl={4}>
                <DocumentLists
                  title="Uploaded Documents"
                  shadow={false}
                  documents={selectedDocuments}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Dialog>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap">
                <input
                  type="text"
                  className="searchBox"
                  placeholder="Search Candidates"
                  onChange={searchStudents}
                />
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Candidates
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={50}
                    showTotalEntries
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Students;
