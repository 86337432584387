/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import axios from "axios";
import AWS from "aws-sdk";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";
import React, { useEffect, useState } from "react";
import { Backdrop, Box, Modal, Typography } from "@mui/material";

const buttonStyle = {
  marginLeft: "10px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function Banner() {
  const s3 = new AWS.S3();
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/home`;
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [file, setFile] = useState(null);
  const [showInfo, setShowInfo] = useState(false);

  const fetchBanner = () => {
    axios.get(baseUrl).then(async (res) => {
      const { data } = res;
      if (data && data.data.length > 0) {
        setId(data?.data[0]?.id);
        setTitle(data?.data[0]?.tag_line);
        setImageUrl(data?.data[0]?.image_url);
      }
    });
  };

  const downloadCurrentImage = () => {
    const link = document.createElement("a");
    link.href = imageUrl;
    link.target = "_blank";
    link.download = "home_banner";
    document.body.appendChild(link);
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    setShowInfo(true);
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleModalClose = () => {
    setShowInfo(false);
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  const performAction = async () => {
    let imgUrl;
    downloadCurrentImage();
    if (file) {
      const params = {
        Bucket: "eurocourse-images/home",
        Key: `${Date.now()}.${file.name}`,
        Body: file,
      };
      const { Location } = await s3.upload(params).promise();
      imgUrl = Location;
      setImageUrl(Location);
    }

    const payload = {
      id,
      tag_line: title,
      image_url: imgUrl,
    };
    let actionEndPoint = "";
    actionEndPoint = `/update`;
    axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
    });
  };

  return (
    <MDBox>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={showInfo}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Old image is saved in your Downloads to avoid image lose.
          </Typography>
          <div className="prompt-button-box">
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleModalClose}>
                CLOSE
              </MDButton>
            </MDBox>
          </div>
        </Box>
      </Modal>
      <MDTypography variant="h5" fontWeight="bold">
        Banner
      </MDTypography>
      <MDBox mt={2}>
        <Box mt={1}>
          <MDBox mt={2}>
            <FormField
              type="text"
              label="Banner Title"
              value={title}
              onChange={(event) => {
                setTitle(event.target.value);
              }}
            />
          </MDBox>
          <MDBox mt={2} mb={2}>
            <label htmlFor="course-image">
              Choose Image:
              <input type="file" name="course-image" onChange={handleFileChange} />
            </label>
          </MDBox>
          {imageUrl ? (
            <MDBox mb={2}>
              <img alt="" className="imageBox" src={imageUrl} />
            </MDBox>
          ) : null}
        </Box>
        <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
          <MDButton variant="outlined" color="info" fullWidth>
            CANCEL
          </MDButton>
          <MDButton
            style={buttonStyle}
            variant="gradient"
            color="info"
            fullWidth
            onClick={performAction}
          >
            SAVE
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default Banner;
