import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function data(tableData, orderState, callback, callback2) {
  const viewOrder = (order) => {
    callback(order);
  };

  const editOrders = (order) => {
    callback2(order);
  };

  const getStatus = (status) => {
    const retVal = orderState.filter((item) => item.title === status);
    return retVal && retVal.length > 0 ? retVal[0].description : "";
  };

  const getColor = (status) => {
    const retVal = orderState.filter((item) => item.title === status);
    return retVal && retVal.length > 0 ? retVal[0].color : "info";
  };

  // Create a map to store unique orders and their details
  const uniqueOrders = {};

  tableData.forEach((item) => {
    if (!uniqueOrders[item.order_id]) {
      uniqueOrders[item.order_id] = {
        ...item,
        courseDetails: [],
        totalAmounts: { full_fees: 0, fees: 0 },
      };
    }
    uniqueOrders[item.order_id].courseDetails.push({
      coursename: item.coursename,
      Batchname: item.Batchname,
    });
    uniqueOrders[item.order_id].totalAmounts.full_fees += item.full_fees;
    uniqueOrders[item.order_id].totalAmounts.fees += item.fees;
  });

  return {
    cols: [
      { Header: "Order Details", accessor: "orderIdDate", width: "15%", align: "left" },
      { Header: "Student Details", accessor: "studentDetails", width: "25%", align: "left" },
      {
        Header: "Course Name & Date",
        accessor: "courseNameDate",
        width: "30%",
        align: "left",
      },
      { Header: "Amount", accessor: "totalAmount", width: "15%", align: "center" },
      { Header: "Status", accessor: "currentStatus", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],

    rws: Object.values(uniqueOrders).map((item) => {
      return {
        orderIdDate: (
          <MDBox ml={-1}>
            <MDTypography component="a" href="#" variant="caption" color="dark" fontWeight="medium">
              {item.order_id}
              <br />
              {new Date(item.date).toDateString()} |{" "}
              {new Date(item.date).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
            </MDTypography>
            <br />
            <MDTypography color="warning" variant="gradient" size="sm" fontWeight="bold">
              Individual Course
            </MDTypography>
            <br />
          </MDBox>
        ),
        studentDetails: (
          <MDTypography component="a" href="#" variant="caption" color="dark" fontWeight="medium">
            {item.full_name}
            <br />
            <span>
              {item.phone} | <span style={{ color: "purple" }}>INDOS - {item.indos_number}</span>
            </span>
            <br />
            {item.email}
          </MDTypography>
        ),
        courseNameDate: (
          <MDTypography component="a" href="#" variant="caption" color="dark" fontWeight="medium">
            {item.courseDetails.map((course, index) => (
              <span key={index}>
                {course.coursename} - <span style={{ color: "purple" }}>{course.Batchname}</span>
                {index < item.courseDetails.length - 1 && (
                  <>
                    <br />
                    <br />
                  </>
                )}
              </span>
            ))}
          </MDTypography>
        ),
        totalAmount: (
          <MDTypography component="a" href="#" variant="caption" color="dark" fontWeight="medium">
            Total: ₹{item.totalAmounts.full_fees}
            <br />
            Paid: ₹<span style={{ color: "green" }}>{item.totalAmounts.fees}</span>
          </MDTypography>
        ),
        currentStatus: (
          <>
            {item.status === "captured" ? (
              <>
                <MDBadge badgeContent="success" color="success" variant="gradient" size="sm" />
                <br />
                <MDBadge
                  badgeContent="online payment"
                  color={getColor(item.status)}
                  variant="gradient"
                  size="sm"
                />
              </>
            ) : (
              <MDBadge
                badgeContent={getStatus(item.status)}
                color={getColor(item.status)}
                variant="gradient"
                size="md"
              />
            )}
          </>
        ),
        action: (
          <MDBox>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => editOrders(item)}
            >
              Edit
            </MDTypography>
          </MDBox>
        ),
      };
    }),
  };
}
