/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
// import course1 from "assets/images/courses/course1.JPG";
// import course2 from "assets/images/courses/course2.JPG";
// import course3 from "assets/images/courses/course3.JPG";
// import course4 from "assets/images/courses/course4.JPG";
// import course5 from "assets/images/courses/course5.JPG";
// import course6 from "assets/images/courses/course6.JPG";
// import team3 from "assets/images/team-3.jpg";
// import team4 from "assets/images/team-4.jpg";

export default function data(tableData, callback, callback2) {
  const editCourse = (index) => {
    callback(index);
  };

  const deleteCategory = (item) => {
    callback2(item);
  };
  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    cols: [
      { Header: "name", accessor: "name", width: "25%", align: "left" },
      { Header: "parent category", accessor: "parentCategory", align: "left" },
      { Header: "description", accessor: "description", width: "45%", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rws: tableData.map((item, index) => {
      console.log(index);
      return {
        name: <Job title={item.name} description="" />,
        parentCategory: (
          <Job title={item.primary_category === "" ? "NA" : item.primary_category} description="" />
        ),
        description: <Job title={item.description} description="" />,
        action: (
          <MDBox>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => editCourse(index)}
            >
              Edit
            </MDTypography>
            &nbsp; &nbsp;
            {item.is_primary ? (
              ""
            ) : (
              <MDTypography
                component="a"
                href="#"
                variant="caption"
                color="text"
                fontWeight="medium"
                onClick={() => deleteCategory(index)}
              >
                Delete
              </MDTypography>
            )}
          </MDBox>
        ),
      };
    }),

    // rws: [
    //   {
    //     name: <Author image={course1} name="Basic Refresher Course" email="Post Sea Course" />,
    //     duration: <Job title="1 Day" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: (
    //       <Author
    //         image={course2}
    //         name="Basic Refresher Course"
    //         email="Personal Survival Techniques(PST)"
    //       />
    //     ),
    //     duration: <Job title="1 Day" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: <Author image={course3} name="Competency Course" email="2Nd Mate Function" />,
    //     duration: <Job title="4 Months" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: (
    //       <Author
    //         image={course4}
    //         name="Competency Course"
    //         email="Advance Shipboard Management (ASM)"
    //       />
    //     ),
    //     duration: <Job title="1 Month, 16 Days" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: <Author image={course5} name="Pre-Sea Course" email="B Tech in Marine Engineering" />,
    //     duration: <Job title="4 Years, 6 Months" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: (
    //       <Author
    //         image={course6}
    //         name="Pre-Sea Course"
    //         email="Certificate Course For Maritime Caterging"
    //       />
    //     ),
    //     duration: <Job title="1 Year" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="inactive" color="warning" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    // ],
  };
}
