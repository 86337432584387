/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function data(tableData, callback, callback2) {
  const editCourse = (index) => {
    callback(index);
  };

  const deleteCategory = (item) => {
    callback2(item);
  };
  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    cols: [
      { Header: "title", accessor: "title", align: "left" },
      { Header: "description", accessor: "description", width: "55%", align: "left" },
      { Header: "date", accessor: "date", align: "left" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rws: tableData.map((item, index) => {
      console.log(index);
      return {
        title: <Job title={item.title} description="" />,
        description: <Job title={item.description} description="" />,
        date: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {new Date(item?.date_created).toLocaleDateString()}
          </MDTypography>
        ),
        action: (
          <MDBox>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => editCourse(index)}
            >
              Edit
            </MDTypography>
            &nbsp; &nbsp;
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => deleteCategory(index)}
            >
              Delete
            </MDTypography>
          </MDBox>
        ),
      };
    }),
  };
}
