/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-routers components

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";
import documentIcon from "assets/images/document_icon.png";

function DocumentLists({ title, shadow, documents }) {
  const image = documentIcon;
  // eslint-disable-next-line no-unused-vars, camelcase
  const renderProfiles =
    documents &&
    documents.length > 0 &&
    // eslint-disable-next-line camelcase
    documents.map(({ document_name, document_link }, index) => (
      <MDBox key={index} component="li" display="flex" alignItems="center" py={1} mb={1}>
        <MDBox mr={2}>
          <MDAvatar src={image} alt="something here" shadow="md" />
        </MDBox>
        <MDBox
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
          justifyContent="center"
        >
          <MDTypography variant="button" fontWeight="medium">
            {/* eslint-disable-next-line camelcase */}
            {document_name}
          </MDTypography>
          {/* <MDTypography variant="caption" color="text">
          {description}
        </MDTypography> */}
        </MDBox>
        <MDBox ml="auto">
          <MDButton
            component="a"
            // eslint-disable-next-line camelcase
            href={document_link}
            target="_blank"
            rel="noreferrer"
            variant="primary"
            download
          >
            Download
          </MDButton>
        </MDBox>
      </MDBox>
    ));

  return (
    <Card sx={{ height: "100%", boxShadow: !shadow && "none" }}>
      <MDBox pt={2} px={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <MDBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderProfiles}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default props for the ProfilesList
DocumentLists.defaultProps = {
  shadow: true,
};

// Typechecking props for the ProfilesList
DocumentLists.propTypes = {
  title: PropTypes.string.isRequired,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  shadow: PropTypes.bool,
};

export default DocumentLists;
