import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

const TableEditor = ({ value, onChange }) => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (value) {
      try {
        const parsedValue = JSON.parse(value);
        if (Array.isArray(parsedValue) && parsedValue.length > 0) {
          setRows(parsedValue);
        } else {
          setRows([[""]]);
        }
      } catch (error) {
        console.error("Error parsing committee data:", error);
        setRows([[""]]);
      }
    } else {
      setRows([[""]]);
    }
  }, [value]);

  const handleCellChange = (rowIndex, colIndex, event) => {
    const newRows = rows.map((row, i) =>
      i === rowIndex ? row.map((cell, j) => (j === colIndex ? event.target.value : cell)) : row
    );
    setRows(newRows);
    onChange(JSON.stringify(newRows));
  };

  const addRow = () => {
    const newRows = rows.length > 0 ? [...rows, Array(rows[0].length).fill("")] : [[""]];
    setRows(newRows);
    onChange(JSON.stringify(newRows));
  };

  const addColumn = () => {
    const newRows = rows.map((row) => [...row, ""]);
    setRows(newRows);
    onChange(JSON.stringify(newRows));
  };

  const deleteRow = (rowIndex) => {
    const newRows = rows.filter((_, i) => i !== rowIndex);
    setRows(newRows.length > 0 ? newRows : [[""]]);
    onChange(JSON.stringify(newRows.length > 0 ? newRows : [[""]]));
  };

  const deleteColumn = (colIndex) => {
    const newRows = rows.map((row) => row.filter((_, i) => i !== colIndex));
    setRows(newRows.length > 0 ? newRows : [[""]]);
    onChange(JSON.stringify(newRows.length > 0 ? newRows : [[""]]));
  };

  const renderTable = () => {
    if (rows.length === 0 || rows[0].length === 0) {
      return (
        <div>
          <p>No data available</p>
          <Button onClick={addRow}>Add Row</Button>
          <Button onClick={addColumn}>Add Column</Button>
        </div>
      );
    }

    return (
      <div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {rows[0].map((_, colIndex) => (
                  <TableCell
                    key={colIndex}
                    sx={{ minWidth: colIndex === 0 ? 200 : 150, padding: "8px" }}
                  >
                    Column {colIndex + 1}
                    <IconButton onClick={() => deleteColumn(colIndex)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, rowIndex) => (
                <TableRow key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <TableCell
                      key={colIndex}
                      sx={{ minWidth: colIndex === 0 ? 200 : 150, padding: "8px" }}
                    >
                      <TextField
                        value={cell}
                        onChange={(e) => handleCellChange(rowIndex, colIndex, e)}
                      />
                    </TableCell>
                  ))}
                  <TableCell sx={{ minWidth: 150, padding: "8px", paddingRight: 0 }}>
                    <IconButton onClick={() => deleteRow(rowIndex)}>
                      <Delete />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button onClick={addRow}>Add Row</Button>
        <Button onClick={addColumn}>Add Column</Button>
      </div>
    );
  };

  return <div>{renderTable()}</div>;
};

TableEditor.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default TableEditor;
