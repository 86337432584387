/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import "./latest-updates.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";

// Data
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
// import PromptModal from "components/PromptModal";
import MDDatePicker from "components/MDDatePicker";
import moment from "moment";
import authorsTableData from "./data/tableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

let tableData = null;

function LatestUpdates() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/latest-updates`;
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState(new Date());

  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [itemIndex, setItemIndex] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setForm = (data) => {
    setId(data?.id);
    setTitle(data?.title);
    setDescription(data?.description);
    setDate(data?.date);
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("UPDATE");
    handleOpen();
  };

  const deleteItem = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("DELETE");
    handleOpen();
  };

  const clearForm = () => {
    setTitle("");
    setDescription("");
  };

  const fetchLatestUpdates = () => {
    clearForm();
    axios.get(baseUrl).then(async (data) => {
      tableData = data.data.data;
      const { cols, rws } = authorsTableData(data.data.data, setitemIndex, deleteItem);
      setRows(rws);
      setColumns(cols);
    });
  };

  useEffect(() => {
    fetchLatestUpdates();
  }, []);

  const addCategory = () => {
    setForm(null);
    setModalActionTitle("ADD");
    handleOpen();
  };

  const handleForm = (event, key) => {
    console.log(itemIndex);
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "TITLE":
        setTitle(value);
        break;
      case "DESCRIPTION":
        setDescription(value);
        break;
      default:
        setTitle(value);
        break;
    }
  };

  const handleDateChange = (event) => {
    setDate(moment(event[0].toISOString()).format("YYYY-MM-DD"));
  };

  const performAction = async (action = "UPDATE") => {
    const payload = {
      id,
      title,
      description,
      date,
    };
    let actionEndPoint = "";
    if (action === "ADD") {
      actionEndPoint = "/add";
      axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchLatestUpdates();
      });
    } else if (action === "UPDATE") {
      actionEndPoint = `/update`;
      axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchLatestUpdates();
      });
    } else if (action === "DELETE") {
      actionEndPoint = `/delete/${tableData[itemIndex].id}`;
      axios.delete(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchLatestUpdates();
      });
    }
  };

  return (
    <Box>
      <MDTypography variant="h5" fontWeight="bold">
        Latest Updates
      </MDTypography>
      {/* Add/Eit Modal */}
      <div className="gov-modal-window">
        {/* <PromptModal title={deleteWarning} /> */}
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mb={2}>
              <MDInput
                type="type"
                label="Title"
                value={title}
                fullWidth
                onChange={(e) => handleForm(e, "TITLE")}
              />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                label="Description"
                multiline
                value={description}
                rows={5}
                fullWidth
                onChange={(e) => handleForm(e, "DESCRIPTION")}
              />
            </MDBox>
            <MDBox mt={2} mb={2}>
              <MDDatePicker
                // options={{ minDate: new Date().toISOString() }}
                value={date}
                onChange={(e) => handleDateChange(e)}
                input={{ placeholder: "Select Date" }}
              />
              {/* <DatePicker onChange={setDate} value={date} /> */}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap-updates">
                <button className="btn" type="button" onClick={addCategory}>
                  Add Data
                </button>
              </div>

              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </Box>
  );
}

export default LatestUpdates;
