/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import "./news-events.css";
import AWS from "aws-sdk";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DatePicker from "react-date-picker";
import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";

// Data
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
// import PromptModal from "components/PromptModal";
import authorsTableData from "./data/tableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

let tableData = null;

AWS.config.update({
  accessKeyId: "AKIAU6GDZZFUMBPCAAQI",
  secretAccessKey: "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",
  region: "ap-south-1",
  signatureVersion: "v4",
});

function NewsAndEvents() {
  const s3 = new AWS.S3();
  const [file, setFile] = useState(null);

  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/news`;
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [itemIndex, setItemIndex] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setForm = (data) => {
    setId(data?.id);
    setTitle(data?.title);
    setStartDate(data?.created_date);
    setDescription(data?.description);
    setImageUrl(data?.image_url);
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("UPDATE");
    handleOpen();
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const deleteItem = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("DELETE");
    handleOpen();
  };

  const clearForm = () => {
    setTitle("");
    setDescription("");
  };

  const fetchCategory = () => {
    clearForm();
    fetch(baseUrl)
      .then((results) => results.json())
      .then(async (data) => {
        // await setTableData(data.resultMessage.data);
        tableData = data.data;
        const { cols, rws } = authorsTableData(data.data, setitemIndex, deleteItem);
        setRows(rws);
        setColumns(cols);
      });
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const addCategory = () => {
    setForm(null);
    setModalActionTitle("ADD");
    handleOpen();
  };

  const handleForm = (event, key) => {
    console.log(itemIndex);
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "DESCRIPTION":
        setDescription(value);
        break;
      case "TITLE":
        setTitle(value);
        break;
      default:
        setTitle(value);
        break;
    }
  };

  const performAction = async (action = "UPDATE") => {
    let imgUrl;
    if (file) {
      const params = {
        Bucket: "eurocourse-images",
        Key: `${Date.now()}.${file.name}`,
        Body: file,
      };
      const { Location } = await s3.upload(params).promise();
      imgUrl = Location;
      setImageUrl(Location);
    }

    const payload = {
      id,
      title,
      description,
      date_created: startDate,
      image_url: imgUrl,
    };
    let actionEndPoint = "";
    if (action === "ADD") {
      actionEndPoint = "/add";
      axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchCategory();
      });
    } else if (action === "UPDATE") {
      actionEndPoint = `/update`;
      axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchCategory();
      });
    } else if (action === "DELETE") {
      actionEndPoint = `/delete/${tableData[itemIndex].id}`;
      axios.delete(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchCategory();
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="gov-modal-window">
        {/* <PromptModal title={deleteWarning} /> */}
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mt={0.5}>
              <MDInput
                type="type"
                label="Title"
                value={title}
                fullWidth
                onChange={(e) => handleForm(e, "TITLE")}
              />
            </MDBox>
            <MDBox mb={2}>
              <DatePicker onChange={setStartDate} value={startDate} />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                label="Description"
                multiline
                value={description}
                rows={5}
                fullWidth
                onChange={(e) => handleForm(e, "DESCRIPTION")}
              />
            </MDBox>
            <MDBox mt={2} mb={2}>
              <label htmlFor="course-image">
                Choose Photo:
                <input type="file" name="course-image" onChange={handleFileChange} />
              </label>
            </MDBox>
            {imageUrl ? (
              <MDBox mb={2}>
                <img alt="" className="imageBox" src={imageUrl} />
              </MDBox>
            ) : null}

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap">
                <button className="btn" type="button" onClick={addCategory}>
                  Add Data
                </button>
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  News and Events
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewsAndEvents;
