/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDProgress from "components/MDProgress";
import MDBadge from "components/MDBadge";
import { Link } from "react-router-dom";

export default function data(tableData, callback, callback2) {
  const editBatch = (batch) => {
    callback(batch);
  };

  const deleteBatch = (batch) => {
    callback2(batch);
  };

  const BookingProgress = ({ totalSeat, availableSeat }) => {
    const booked_seat = totalSeat > 0 ? totalSeat - availableSeat : 0;
    const booked_seat_percentage = totalSeat > 0 ? (booked_seat / totalSeat) * 100 : 0;

    return (
      <MDBox>
        <MDTypography
          fontSize="13px"
          display="inline-block"
          verticalAlign="middle"
          lineHeight="1.5em"
        >
          {isNaN(booked_seat_percentage) ? 0 : booked_seat_percentage.toFixed(1)}%
        </MDTypography>
        <MDBox
          style={{
            display: "inline-block",
            width: "150px",
            margin: "0 10px",
            verticalAlign: "middle",
            lineHeight: "1.5em",
          }}
        >
          <MDProgress variant="determinate" value={booked_seat_percentage} />
        </MDBox>
        <MDTypography
          display="inline-block"
          verticalAlign="middle"
          whiteSpace="nowrap"
          lineHeight="1.5em"
        >
          <span style={{ fontSize: "13px", color: "#1A73E8" }}>{booked_seat}</span>
          <span style={{ fontSize: "13px", color: "black" }}>/</span>
          <span style={{ fontSize: "13px", color: "black" }}>{totalSeat}</span>
          &nbsp;<span style={{ fontSize: "13px", color: "black" }}>Seats</span>
        </MDTypography>
      </MDBox>
    );
  };

  return {
    cols: [
      { Header: "batch id", accessor: "id", width: "2%", align: "left" },
      { Header: "course name", accessor: "course_name", width: "28%", align: "left" },
      { Header: "start date", accessor: "startDate", width: "15%", align: "center" },
      { Header: "end date", accessor: "endDate", width: "15%", align: "center" },
      { Header: "booking status", accessor: "bookingStatus", width: "45%", align: "center" },
      { Header: "bookings", accessor: "bookings", width: "5%", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    // eslint-disable-next-line arrow-body-style
    rws: tableData.map((item) => {
      return {
        id: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {item.id}
          </MDTypography>
        ),
        course_name: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {item.course_name}
          </MDTypography>
        ),
        startDate: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {new Date(item.start_date).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </MDTypography>
        ),
        endDate: (
          <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
            {new Date(item.end_date).toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })}
          </MDTypography>
        ),
        bookingStatus: (
          <MDBox>
            <BookingProgress totalSeat={item.seats} availableSeat={item.available_seats} />
          </MDBox>
        ),
        bookings: (
          <Link to={"/student/batch-management"} style={{ textDecoration: "none" }}>
            <MDBadge badgeContent="View Bookings" color="success" variant="gradient" size="sm" />
          </Link>
        ),
        action: (
          <MDBox>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => editBatch(item)}
            >
              Edit
            </MDTypography>
            &nbsp; &nbsp;
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              whiteSpace="nowrap"
              onClick={() => deleteBatch(item)}
            >
              Delete
            </MDTypography>
          </MDBox>
        ),
      };
    }),
  };
}
