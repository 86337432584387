/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import "./table.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";

// Data
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import { Alert, Autocomplete, Snackbar, Switch } from "@mui/material";
import FormField from "layouts/applications/wizard/components/FormField";
// import PromptModal from "components/PromptModal";
import authorsTableData from "./data/authorsTableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: 700,
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

// const sampleCategory = [
//   {
//     id: 1,
//     name: "Parent Category 1",
//   },
//   { id: 2, name: "Parent Category 2" },
// ];

let tableData = null;

function Category() {
  // const deleteWarning = "Are you sure want to delete the record?";
  // const baseUrl = "https://65.0.92.174/api/category";
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/category`;
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [open, setOpen] = useState(false);
  // const [action, setAction] = useState("ADD");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  // const [tableData, setTableData] = useState(null);
  const [name, setName] = useState("");
  const [orderCategory, setOrderCategory] = useState();
  const [description, setDescription] = useState("");
  const [isPrimary, setIsPrimary] = useState(false);
  const [primaryCategory, setPrimaryCategory] = useState(0);
  const [primaryCategoryList, setPrimaryCategoryList] = useState([]);

  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [itemIndex, setItemIndex] = useState(0);
  const [alertText, setAlertText] = useState("Success");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setForm = (data) => {
    console.log(data);
    const primaryVal = data?.is_primary === 1;
    setName(data?.name);
    setDescription(data?.description);
    setIsPrimary(primaryVal);
    setOrderCategory(data?.order_category);
    setPrimaryCategory(data ? data?.primary_category : primaryCategoryList[0].name);
    // setEndDate(data ? data?.updated_at.split("T")[0].replaceAll("-", "/") : "");
    // setFees(data ? data?.fees : "");
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("UPDATE");
    handleOpen();
  };

  const deleteItem = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("DELETE");
    handleOpen();
  };

  const clearForm = () => {
    setName("");
    setDescription("");
  };

  const fetchCategory = () => {
    clearForm();
    axios.get(baseUrl).then(async (data) => {
      // await setTableData(data.resultMessage.data);
      tableData = data.data.resultMessage.data;
      const pCategory = tableData.filter((pData) => pData.is_primary === 1);
      setPrimaryCategoryList(pCategory);
      const { cols, rws } = authorsTableData(
        data.data.resultMessage.data,
        setitemIndex,
        deleteItem
      );

      // console.log('colandrows',cols,rws);
      setRows(rws);
      setColumns(cols);
    });
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  const addCategory = () => {
    setIsPrimary(false);
    setForm(null);
    setModalActionTitle("ADD");
    handleOpen();
  };

  const handleForm = (event, key) => {
    console.log(itemIndex);
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "NAME":
        setName(value);
        break;
      case "DESCRIPTION":
        if (value) {
          setDescription(value);
        }
        break;
      default:
        setName(value);
        break;
    }
  };

  const performAction = async (action = "UPDATE") => {
    const payload = {
      name,
      description,
      primary_category: !isPrimary ? primaryCategory : "",
      is_primary: isPrimary ? 1 : 0,
      order_category: orderCategory,
    };
    let actionEndPoint = "";
    if (action === "ADD") {
      actionEndPoint = "/add";
      axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        setAlertType("success");
        setAlertText(response.data.resultMessage.msgs);
        setShowAlert(true);
        setOpen(false);
        fetchCategory();
      });
    } else if (action === "UPDATE") {
      actionEndPoint = `/update/${tableData[itemIndex].id}`;
      axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        setAlertType("success");
        setAlertText(response.data.resultMessage.msgs);
        setShowAlert(true);
        setOpen(false);
        fetchCategory();
      });
    } else if (action === "DELETE") {
      actionEndPoint = `/delete/${tableData[itemIndex].id}`;
      axios.delete(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        if (response.data.resultCode === "201") {
          setAlertType("error");
        } else {
          setAlertType("success");
        }
        setAlertText(response.data.resultMessage.msg);
        setShowAlert(true);
        setOpen(false);
        fetchCategory();
      });
    }

    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(payload),
    // };
    // console.log(payload);

    // const response = await fetch(`${baseUrl}${actionEndPoint}`, requestOptions);
    // const data = await response.json();
  };

  // const addUpdateCourse = (payload) => {
  //   axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
  //     console.log(response);
  //     setOpen(false);
  //     fetchCourses();
  //   });
  // };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="modal-window">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          open={showAlert}
          message={alertText}
          severity={alertType}
          onClose={() => setShowAlert(false)}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity={alertType}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>
        {/* <PromptModal title={deleteWarning} /> */}
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mb={2}>
              <MDInput
                type="type"
                label="Category Name"
                value={name}
                fullWidth
                onChange={(e) => handleForm(e, "NAME")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="number"
                label="Order Category"
                value={orderCategory}
                fullWidth
                onChange={(e) => setOrderCategory(e.target.value)}
              />
            </MDBox>
            <MDBox mt={0.5}>
              <MDTypography variant="button" color="text">
                Set as Parent Category
              </MDTypography>
              <Switch checked={isPrimary} onChange={() => setIsPrimary(!isPrimary)} />
            </MDBox>
            {!isPrimary ? (
              <MDBox mb={2}>
                <Autocomplete
                  defaultValue={primaryCategoryList[0]}
                  options={primaryCategoryList}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, v) => setPrimaryCategory(v.name)}
                  renderInput={(params) => (
                    <FormField
                      {...params}
                      label="Parent Category"
                      InputLabelProps={{ shrink: true }}
                    />
                  )}
                />
              </MDBox>
            ) : null}

            <MDBox mb={2}>
              <MDInput
                label="Description"
                multiline
                value={description}
                rows={5}
                fullWidth
                onChange={(e) => handleForm(e, "DESCRIPTION")}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap">
                <button className="btn" type="button" onClick={addCategory}>
                  Add Category
                </button>
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Categories
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Category;
