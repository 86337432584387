/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";
import AWS from "aws-sdk";

let tableData = null;

const buttonStyle = {
  marginLeft: "10px",
};

AWS.config.update({
  accessKeyId: "AKIAU6GDZZFUMBPCAAQI",
  secretAccessKey: "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",
  region: "ap-south-1",
  signatureVersion: "v4",
});

function FromTheDeskPrincipal() {
  const s3 = new AWS.S3();
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/desk-principle`;
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [designation, setDesignation] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  const handleForm = (event, key) => {
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "TITLE":
        setTitle(value);
        break;
      case "DESIGNATION":
        setDesignation(value);
        break;
      case "DESC":
        if (value) {
          setDescription(event);
        }
        break;
      default:
        setTitle(value);
        break;
    }
  };

  const fetchAbout = () => {
    axios.get(baseUrl).then(async (data) => {
      // await setTableData(data.resultMessage.data);
      tableData = data.data.data;
      if (data) {
        setTitle(tableData[0].title);
        setDesignation(tableData[0].designation);
        setDescription(tableData[0].description);
        setImageUrl(tableData[0].image_url);
      }
    });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  useEffect(() => {
    fetchAbout();
  }, []);

  const performAction = async () => {
    let imgUrl;
    if (file) {
      const params = {
        Bucket: "eurocourse-images/from-the-fesk-of-principal",
        Key: `${Date.now()}.${file.name}`,
        Body: file,
      };
      const { Location } = await s3.upload(params).promise();
      imgUrl = Location;
      setImageUrl(Location);
    }

    const payload = {
      id: tableData[0].id,
      title,
      designation,
      description,
      image_url: imgUrl,
    };
    let actionEndPoint = "";

    actionEndPoint = `/update`;
    axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      fetchAbout();
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px" }}>
        <MDBox>
          <MDTypography variant="h5" fontWeight="bold">
            From the Desk of Principal
          </MDTypography>
          <MDBox mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDInput
                  type="type"
                  label="Name"
                  value={title}
                  fullWidth
                  onChange={(e) => handleForm(e, "TITLE")}
                />
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  type="type"
                  label="Designation"
                  value={designation}
                  fullWidth
                  onChange={(e) => handleForm(e, "DESIGNATION")}
                />
              </Grid>
              <Grid item xs={12}>
                <MDEditor value={description} onChange={(e) => setDescription(e)} />
              </Grid>
              <Grid item xs={12}>
                <label htmlFor="course-image">
                  Choose Image:
                  <input type="file" name="course-image" onChange={handleFileChange} />
                </label>
              </Grid>
              {imageUrl ? (
                <Grid item xs={12}>
                  <img alt="" className="imageBox" src={imageUrl} />
                </Grid>
              ) : null}
            </Grid>
            <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
              <MDButton variant="outlined" color="info" fullWidth>
                CANCEL
              </MDButton>
              <MDButton
                style={buttonStyle}
                variant="gradient"
                color="info"
                fullWidth
                onClick={performAction}
              >
                SAVE
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default FromTheDeskPrincipal;
