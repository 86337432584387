/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import { Switch } from "@mui/material";

// import Typography from "@mui/material/Typography";
import "./table.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";

// Data
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import authorsTableData from "./data/authorsTableData";

// import { DesktopDatePicker } from "@mui/x-date-pickers";
// import { TextField } from "@mui/material";
// import projectsTableData from "layouts/tables/data/projectsTableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: 700,
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

let tableData = null;

function Documents() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [open, setOpen] = useState(false);
  // const [action, setAction] = useState("ADD");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [active, setActive] = useState(false);
  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [itemIndex, setItemIndex] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const setForm = async (data) => {
    setName(data ? data?.name : "");
    setDescription(data ? data?.description : "");
    setActive(data?.active);
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("UPDATE");
    handleOpen();
  };

  const deleteItem = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("DELETE");
    handleOpen();
  };

  const fetchDocuments = () => {
    axios.get(`${baseUrl}/documents`).then(async (data) => {
      // await setTableData(data.resultMessage.data);
      // setBatches(data.resultMessage.data);
      tableData = data.data.resultMessage.data;
      // setCategoryList(data?.resultMessage?.data);
      const { cols, rws } = authorsTableData(
        data.data.resultMessage.data,
        setitemIndex,
        deleteItem
      );
      setRows(rws);
      setColumns(cols);
    });
  };
  useEffect(() => {
    fetchDocuments();
  }, []);

  const addBatches = () => {
    // setAction("ADD");

    setForm(null);
    setModalActionTitle("ADD");
    handleOpen();
  };

  const handleForm = (event, key) => {
    console.log(itemIndex);
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "NAME":
        setName(value);
        break;
      case "DESCRIPTION":
        if (value) {
          setDescription(value);
        }
        break;
      default:
        console.log(event);
        break;
    }
  };

  const refreshGrid = () => {
    setOpen(false);
    fetchDocuments();
  };

  const performAction = async (action = "UPDATE") => {
    const payload = {
      name,
      description,
      active,
    };
    let actionEndPoint = "/documents";
    if (action === "ADD") {
      actionEndPoint += "/add";
      axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        refreshGrid();
      });
      console.log(action, payload);
    } else if (action === "UPDATE") {
      actionEndPoint += `/update/${tableData[itemIndex].id}`;
      axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        refreshGrid();
      });
    } else if (action === "DELETE") {
      actionEndPoint += `/delete/${tableData[itemIndex].id}`;
      axios.delete(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        refreshGrid();
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="modal-window">
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mb={2}>
              <MDInput
                type="type"
                label="Document Name"
                value={name}
                fullWidth
                onChange={(e) => handleForm(e, "NAME")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="type"
                label="Document Description"
                value={description}
                fullWidth
                onChange={(e) => handleForm(e, "DESCRIPTION")}
              />
            </MDBox>
            <MDBox mt={0.5}>
              <MDTypography variant="button" color="text">
                Is Active ?
              </MDTypography>
              <Switch checked={active} onChange={() => setActive(!active)} />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap">
                <button className="btn" type="button" onClick={addBatches}>
                  Add Document Type
                </button>
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Documents
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Documents;
