// components/custom-editor.js

import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "@ckeditor/ckeditor5-build-classic";
import PropTypes from "prop-types";

// Editor configuration without the custom upload adapter
const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "outdent",
    "indent",
    "|",
    "blockQuote",
    "insertTable",
    "alignment",
    "codeBlock",
    "fontSize",
    "fontBackgroundColor",
    "fontColor",
    "fontFamily",
    "removeFormat",
    "specialCharacters",
    "style",
    "undo",
    "redo",
  ],
  // Removing the extraPlugins configuration to eliminate the custom file upload adapter
};

function CustomEditor({ initialData, setData }) {
  return (
    <CKEditor
      editor={Editor}
      config={editorConfiguration}
      data={initialData}
      onChange={(event, editor) => {
        const data = editor.getData();
        setData(data);
      }}
    />
  );
}
CustomEditor.propTypes = {
  initialData: PropTypes.string,
  setData: PropTypes.func.isRequired,
};

CustomEditor.defaultProps = {
  initialData: "",
};

export default CustomEditor;
