/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
// import Tooltip from "@mui/material/Tooltip";
import PropTypes from "prop-types";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
// import MDButton from "components/MDButton";

// Wizard application components

// Images
import team2 from "assets/images/candidate-avatar.png";
import MDInput from "components/MDInput";

function BatchManagementProfile({ candidate }) {
  return (
    <MDBox>
      {/* <MDBox width="82%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Let&apos;s start with the basic information
          </MDTypography>
        </MDBox>
        <MDTypography variant="body2" color="text">
          Let us know your name and email address. Use an address you don&apos;t mind other users
          contacting you at
        </MDTypography>
      </MDBox> */}
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} container justifyContent="center">
            <MDBox position="relative" height="max-content" mx="auto">
              <MDAvatar src={team2} alt="profile picture" size="xxl" variant="rounded" />
              <MDBox alt="spotify logo" position="absolute" right={0} bottom={0} mr={-1} mb={-1}>
                {/* <Tooltip title="Edit" placement="top">
                  <MDButton variant="gradient" color="info" size="small" iconOnly>
                    <Icon>edit</Icon>
                  </MDButton>
                </Tooltip> */}
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={8}>
            <MDBox mb={2}>
              <MDInput
                type="text"
                fullWidth="true"
                value={candidate.full_name}
                label="Full Name"
                disabled="true"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="text"
                fullWidth="true"
                label="Mobile"
                value={candidate.phone}
                disabled="true"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                fullWidth="true"
                label="Email Address"
                value={candidate.email}
                disabled="true"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                fullWidth="true"
                label="Date of Birth"
                value={candidate.date_of_birth}
                disabled="true"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                fullWidth="true"
                label="Next of Kin Name"
                value={candidate.name_of_next_of_kin}
                disabled="true"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="email"
                fullWidth="true"
                label="Next of Kin Number"
                value={candidate.contact_of_next_of_kin}
                disabled="true"
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

BatchManagementProfile.propTypes = {
  candidate: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default BatchManagementProfile;
