/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Card } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import AboutLanding from "./about";
import Banner from "./banner";
import FooterCMS from "./footer";
import LatestUpdates from "./latest-updates";
import ApplyForBooking from "./apply-now-for-booking";
import PromoVideo from "./promo-video";
import Section5 from "./section-5";
import Section6 from "./section-6";

function Landing() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px" }}>
        <Banner />
      </Card>
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px", margin: "10px 0px" }}>
        <LatestUpdates />
      </Card>
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px", margin: "10px 0px" }}>
        <ApplyForBooking />
      </Card>
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px", margin: "10px 0px" }}>
        <PromoVideo />
      </Card>
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px", margin: "10px 0px" }}>
        {/* <AboutLanding /> */}
        <Section5 />
      </Card>
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px", margin: "10px 0px" }}>
        <Section6 />
      </Card>
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px", margin: "10px 0px" }}>
        <FooterCMS />
      </Card>
    </DashboardLayout>
  );
}

export default Landing;
