import React, { useState, useEffect } from "react";
import axios from "axios";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Card, Grid, TextField, Button, Typography, Box, IconButton } from "@mui/material";
import MDButton from "components/MDButton";
import DeleteIcon from "@mui/icons-material/Delete";

const MetaTags = () => {
  const [metaTagsList, setMetaTagsList] = useState([
    { id: null, page_name: "", meta_title: "", meta_description: "" },
  ]);

  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/meta-tags`;

  const handleChange = (index, field, value) => {
    const updatedMetaTags = [...metaTagsList];
    updatedMetaTags[index][field] = value;
    setMetaTagsList(updatedMetaTags);
  };

  const addRow = () => {
    setMetaTagsList([...metaTagsList, { page_name: "", meta_title: "", meta_description: "" }]);
  };

  const deleteRow = (index) => {
    setMetaTagsList((prevList) => prevList.filter((_, i) => i !== index));
  };

  const performAction = async () => {
    try {
      for (const metaTag of metaTagsList) {
        const payload = {
          page_name: metaTag.page_name,
          meta_title: metaTag.meta_title,
          meta_description: metaTag.meta_description,
        };

        let actionEndPoint = metaTag.id ? `/update` : `/add`;

        if (metaTag.id) {
          payload.id = metaTag.id;
          await axios.put(`${baseUrl}${actionEndPoint}`, payload);
        } else {
          const response = await axios.post(`${baseUrl}${actionEndPoint}`, payload);

          metaTag.id = response.data.data.id;
        }
      }

      fetchMetaTags();
    } catch (error) {
      console.error("Error performing action:", error);
    }
  };

  const fetchMetaTags = async () => {
    try {
      const response = await axios.get(baseUrl);
      if (response.data.status) {
        const mappedData = response.data.data.map((item) => ({
          id: item.id,
          page_name: item.page_name,
          meta_title: item.meta_title,
          meta_description: item.meta_description,
        }));
        setMetaTagsList(mappedData);
      } else {
        setMetaTagsList([{ page_name: "", meta_title: "", meta_description: "" }]);
      }
    } catch (error) {
      console.error("Error fetching meta tags:", error);
    }
  };

  useEffect(() => {
    fetchMetaTags();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", padding: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="div" align="center">
              <Box sx={{ fontWeight: "bold", fontSize: "25px", mb: "15px" }}>Meta tags</Box>
            </Typography>
            <Grid container spacing={2}>
              {metaTagsList.map((metaTag, index) => (
                <Grid container item spacing={2} key={index}>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Page name"
                      value={metaTag.page_name}
                      onChange={(e) => handleChange(index, "page_name", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Meta title"
                      value={metaTag.meta_title}
                      onChange={(e) => handleChange(index, "meta_title", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <TextField
                      fullWidth
                      label="Meta description"
                      value={metaTag.meta_description}
                      onChange={(e) => handleChange(index, "meta_description", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <IconButton aria-label="delete" color="error" onClick={() => deleteRow(index)}>
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Box sx={{ marginTop: "25px", textAlign: "center" }}>
              <MDButton
                style={{ marginRight: "10px" }}
                variant="contained"
                color="info"
                onClick={addRow}
              >
                Add new row
              </MDButton>
              <MDButton
                style={{ marginRight: "10px" }}
                variant="contained"
                color="info"
                onClick={performAction}
              >
                SAVE
              </MDButton>
            </Box>
          </Grid>
        </Grid>
      </Card>
    </DashboardLayout>
  );
};

export default MetaTags;
