/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import "./course.css";
// import DatePicker from "react-date-picker";
import AWS from "aws-sdk";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import moment from "moment-timezone";
// import MDButton from "components/MDButton";

// Data
import authorsTableData from "layouts/accademic/packages/data/authorsTableData";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import {
  Alert,
  Autocomplete,
  Checkbox,
  Chip,
  FormControlLabel,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const alertStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "hidden",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

let tableData = null;
let msterDocuments = [];
const signedUrl = "";

AWS.config.update({
  accessKeyId: "AKIAU6GDZZFUMBPCAAQI",
  secretAccessKey: "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",
  region: "ap-south-1",
  signatureVersion: "v4",
});

function Package() {
  const s3 = new AWS.S3();
  const [file, setFile] = useState(null);
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
  const [courseList, setCourseList] = useState(null);
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedCourses, setSelectedCourses] = useState([]);
  // const [tableData, setTableData] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("");
  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [courseId, setCourseId] = useState(0);
  const [alertText, setAlertText] = useState("Success");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [price, setPrice] = useState(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);

  const setForm = (data) => {
    setIsActive(data && data.isActive);
    setName(data ? data?.packageName : "");
    setSelectedCourses(data ? data.courses : []);
    setImageUrl(data ? data?.packageImage : "");
    setPrice(data ? data.price : 0);
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = (course, mode) => {
    setCourseId(course.id);
    if (mode === "EDIT") {
      setButtonTitle("UPDATE");
      if (tableData?.length > 0) {
        setForm(course);
      }
      handleOpen();
    } else {
      setButtonTitle("DELETE");
      handleDelete();
    }
  };

  const setTableData = (data) => {
    const { cols, rws } = authorsTableData(data, setitemIndex);
    setRows(rws);
    setColumns(cols);
  };

  const fetchpackages = () => {
    axios
      .get(`${baseUrl}/package/list`)
      .then(async (data) => {
        tableData = data.data.resultMessage.data;
        setTableData(data.data.resultMessage.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchCoursesList = () => {
    axios
      .get(`${baseUrl}/courses`)
      .then(async (data) => {
        tableData = data.data.resultMessage.data;
        setCourseList(data.data.resultMessage.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchCoursesList();
  }, []);

  const searchCourse = (event) => {
    const searchText = event.target.value;
    if (searchText.length > 0) {
      const filtered =
        tableData &&
        tableData.length > 0 &&
        tableData?.filter((course) => course?.packageName?.toLowerCase().indexOf(searchText) > -1);
      setTableData(filtered);
    } else {
      setTableData(tableData);
    }
  };

  useEffect(() => {
    fetchpackages();
  }, []);

  const addPackage = () => {
    setForm(null);
    setIsActive(true);
    setModalActionTitle("ADD");
    handleOpen();
  };

  const handleForm = (event, key) => {
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "CTITLE":
        setName(value);
        break;
      case "Price":
        setPrice(value);
        break;
      default:
        setName(value);
        break;
    }
  };

  const performAction = async (action = "UPDATE") => {
    try {
      let imgUrl;
      if (file) {
        const params = {
          Bucket: "eurocourse-images",
          Key: `${Date.now()}.${file.name}`,
          Body: file,
        };
        const { Location } = await s3.upload(params).promise();
        imgUrl = Location;
        setImageUrl(Location);
      }
      const courseIds =
        selectedCourses &&
        selectedCourses.length > 0 &&
        selectedCourses?.map((course) => course.id);
      const payload = {
        isActive: isActive,
        packageName: name,
        created_by: 101,
        courses_ids: courseIds,
        packageImage: imgUrl,
        price: price,
      };

      let actionEndPoint = "";
      let axiosMethod;
      switch (action) {
        case "ADD":
          actionEndPoint = "/package/add-package";
          axiosMethod = axios.post;
          break;
        case "UPDATE":
          actionEndPoint = `/package/update-package/${courseId}`;
          axiosMethod = axios.put;
          break;
        case "DELETE":
          actionEndPoint = `/package/delete/${courseId}`;
          axiosMethod = axios.delete;
          break;
        default:
          throw new Error("Invalid action type");
      }

      // Execute the appropriate request based on the action
      console.log("payload", payload);
      const response = await axiosMethod(`${baseUrl}${actionEndPoint}`, payload);

      const { resultCode, resultMessage } = response.data;

      // Handle the response based on the resultCode
      if (resultCode === "200") {
        setAlertType(action === "DELETE" ? "error" : "success");
        setAlertText(resultMessage.msg || resultMessage.msgs);
      } else {
        setAlertType(action === "DELETE" ? "success" : "error");
        setAlertText(resultMessage.msg || resultMessage.msgs);
      }
      if (resultCode === "200" && action === "UPDATE") {
        setAlertType("success");
        setAlertText(resultMessage.msg || resultMessage.msgs);
      }
      if (resultCode === "201" && action === "ADD") {
        setAlertType("success");
        setAlertText(resultMessage.msg || resultMessage.msgs);
      }
      setShowAlert(true);
      setOpen(false);

      if (action === "DELETE") {
        setDeleteOpen(false);
      }

      fetchpackages();
    } catch (error) {
      console.error("Error performing action:", error);
      setAlertType("error");
      setAlertText("error while performing the task");
      setShowAlert(true);
    }
  };
  const formattedDate = new Date().toISOString().split("T")[0];

  const verifyBatch = async (id) => {
    const res = await axios.get(`${baseUrl}/batches/get/list?course_id=${id}`);
    console.log(res);

    if (res?.data?.data && res?.data?.data.length > 0) {
      let status = false;
      for (let i of res?.data?.data) {
        if (i.status !== "Closed" && i.start_date >= formattedDate) {
          status = true;
        }
      }

      return status;
    }
  };
  const handleCourseChange = async (event, value) => {
    if (await verifyBatch(value.id)) {
      if (value) {
        setSelectedCourses((prev) => {
          if (prev && prev?.some((course) => course.id === value.id)) {
            return prev;
          }
          return [...prev, value];
        });
      }
    } else {
      setAlertType("error");
      setAlertText("All Batches of this course is closed.");
      setShowAlert(true);
    }
  };

  const handleRemoveCourse = (courseId) => {
    setSelectedCourses((prev) => prev?.filter((course) => course.id !== courseId));
  };

  // Filter out already selected courses from options
  const filteredOptions =
    courseList &&
    courseList.length > 0 &&
    courseList?.filter(
      (course) => !selectedCourses?.some((selectedCourse) => selectedCourse?.id === course?.id)
    );
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  console.log("file", file);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="modal-window">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          open={showAlert}
          message={alertText}
          severity={alertType}
          onClose={() => setShowAlert(false)}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity={alertType}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={deleteOpen}
          onClose={handleDeleteClose}
          closeAfterTransition
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Box sx={alertStyle}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Do you want to delete the selected Course?
            </Typography>
            <div className="prompt-button-box">
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => performAction(modalActionTitle)}
                >
                  {modalActionTitle}
                </MDButton>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="outlined" color="info" fullWidth onClick={handleDeleteClose}>
                  CANCEL
                </MDButton>
              </MDBox>
            </div>
          </Box>
        </Modal>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}

          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mt={0.5}>
              <MDTypography variant="button" color="text">
                Active
              </MDTypography>
              <Switch checked={isActive} onChange={() => setIsActive(!isActive)} />
            </MDBox>

            <MDBox mb={2} mt={3}>
              <MDInput
                type="type"
                value={name}
                label="Package Name"
                fullWidth
                onChange={(e) => handleForm(e, "CTITLE")}
              />
            </MDBox>
            <MDBox mb={2} mt={3}>
              <MDInput
                type="number"
                value={price}
                label="Enter price"
                fullWidth
                onChange={(e) => handleForm(e, "Price")}
              />
            </MDBox>
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 2 }}>
              {selectedCourses &&
                selectedCourses.length > 0 &&
                selectedCourses?.map((course) => (
                  <Chip
                    key={course.id}
                    label={course.title}
                    onDelete={() => handleRemoveCourse(course.id)}
                    variant="outlined"
                  />
                ))}
            </Box>
            <MDBox mb={2}>
              <Autocomplete
                options={filteredOptions}
                getOptionLabel={(option) => option.title}
                onChange={handleCourseChange}
                renderInput={(params) => (
                  <TextField {...params} label="Add Package" variant="outlined" />
                )}
                defaultValue={
                  modalActionTitle === "ADD"
                    ? courseList && courseList[0]
                    : selectedCourses && selectedCourses[0]
                }
              />
            </MDBox>
            <MDBox>
              <label htmlFor="course-image">
                Choose Package image:
                <input type="file" name="course-image" onChange={handleFileChange} />
              </label>
            </MDBox>
            {file?.name ? (
              <MDBox mb={2}>
                <img alt="" className="imageBox" src={file?.name} />
              </MDBox>
            ) : (
              <MDBox mb={2}>
                <img alt="" className="imageBox" src={imageUrl} />
              </MDBox>
            )}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap">
                <input
                  type="text"
                  className="searchBox"
                  placeholder="Search Packages"
                  onChange={searchCourse}
                />
                <button className="btn" type="button" onClick={addPackage}>
                  Add Package
                </button>
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Packages
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage
                    showTotalEntries
                    noEndBorder
                  />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      minHeight: "200px",
                      alignItems: "center",
                    }}
                  >
                    <Typography component="h1"> No package available </Typography>
                  </Box>
                )}
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Package;
