/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Backdrop, Box, Modal, Switch, Typography } from "@mui/material";
import axios from "axios";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import AWS from "aws-sdk";

// NewProduct page components
import React, { useEffect, useState } from "react";
import MDEditor from "components/MDEditor";

const buttonStyle = {
  marginLeft: "10px",
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

function Section5() {
  const s3 = new AWS.S3();
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/landing-section5`;
  const [isActive, setIsActive] = useState(true);
  const [isImageChanged, setIsImageChanged] = useState(false);
  const [file, setFile] = useState(null);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [showInfo, setShowInfo] = useState(false);

  const setPage = (pageData) => {
    setId(pageData?.id);
    if (pageData?.active) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    setTitle(pageData?.title);
    setDescription(pageData?.description);
    setImageUrl(pageData?.image_url);
  };

  const fetchApplyForBooking = () => {
    axios.get(`${baseUrl}`).then(async (res) => {
      setPage(res.data.data[0]);
    });
  };
  const downloadCurrentImage = () => {
    const link = document.createElement("a");
    // Set link's href to point to the Blob URL
    link.href = imageUrl;
    // eslint-disable-next-line no-restricted-globals
    link.download = "apply_for_booking";
    // Append link to the body
    document.body.appendChild(link);
    // Dispatch click event on the link
    // This is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
    document.body.removeChild(link);
    setShowInfo(true);
  };

  useEffect(() => {
    fetchApplyForBooking();
    console.log(title);
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setIsImageChanged(true);
  };

  const handleForm = (event, key) => {
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "TITLE":
        setTitle(value);
        break;
      case "DESCRIPTION":
        if (value) {
          setDescription(value);
        }
        break;
      default:
        setTitle(value);
        break;
    }
  };

  const performAction = async () => {
    let imgUrl;
    if (isImageChanged) {
      downloadCurrentImage();
    }
    if (file) {
      const params = {
        Bucket: "eurocourse-images/apply-for-booking",
        Key: `${Date.now()}.${file.name}`,
        Body: file,
      };
      const { Location } = await s3.upload(params).promise();
      imgUrl = Location;
      setImageUrl(Location);
    }

    const payload = {
      id,
      active: isActive,
      title,
      description,
      image_url: imgUrl,
    };
    let actionEndPoint = "";
    actionEndPoint = `/update`;
    axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
    });
  };

  const handleModalClose = () => {
    setShowInfo(false);
  };

  const handleRichEditor = (data) => {
    setDescription(data);
  };

  return (
    <MDBox>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={showInfo}
        onClose={handleModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Box sx={style}>
          <Typography id="spring-modal-title" variant="h6" component="h2">
            Old image is saved in your Downloads to avoid image lose.
          </Typography>
          <div className="prompt-button-box">
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth onClick={handleModalClose}>
                CLOSE
              </MDButton>
            </MDBox>
          </div>
        </Box>
      </Modal>
      <MDTypography variant="h5" fontWeight="bold">
        Section 5
      </MDTypography>
      <Box mt={1}>
        <MDBox mt={0.5}>
          <MDTypography variant="button" color="text">
            Display in Landing Page?
          </MDTypography>
          <Switch checked={isActive} onChange={() => setIsActive(!isActive)} />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            type="type"
            label="Title"
            value={title}
            fullWidth
            onChange={(e) => handleForm(e, "TTILE")}
          />
        </MDBox>
        <MDBox mt={2}>
          <MDTypography variant="h5" fontWeight="bold">
            Details
          </MDTypography>
          <MDEditor value={description} onChange={(e) => handleRichEditor(e)} />
        </MDBox>
        <MDBox mt={2} mb={2}>
          <label htmlFor="course-image">
            Choose Image:
            <input type="file" name="course-image" onChange={handleFileChange} />
          </label>
        </MDBox>
        {imageUrl ? (
          <MDBox mb={2}>
            <img alt="" className="imageBox" src={imageUrl} />
          </MDBox>
        ) : null}

        <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
          <MDButton variant="outlined" color="info" fullWidth>
            CANCEL
          </MDButton>
          <MDButton
            style={buttonStyle}
            variant="gradient"
            color="info"
            fullWidth
            onClick={performAction}
          >
            SAVE
          </MDButton>
        </MDBox>
      </Box>
    </MDBox>
  );
}

export default Section5;
