/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

export default function data(tableData, callback, callback2, types) {
  const editCourse = (index) => {
    callback(index);
  };

  // eslint-disable-next-line no-unused-vars
  const deleteCategory = (item) => {
    callback2(item);
  };
  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  const getEmailType = (id) => {
    const type = types && types.length > 0 && types.filter((item) => item.id === id);
    return type && type.length > 0 && type[0].name;
  };

  return {
    cols: [
      { Header: "type", accessor: "type", align: "left" },
      { Header: "subject", accessor: "subject", align: "left" },
      { Header: "message", accessor: "message", align: "left" },
      { Header: "signature", accessor: "signature", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rws: tableData.map((item, index) => {
      console.log(index);
      return {
        type: <Job title={getEmailType(item.type_id)} description="" />,
        // type: () => getEmailType(item.type_id),
        subject: <Job title={item.subject} description="" />,
        message: <span dangerouslySetInnerHTML={{ __html: item.message }} />,
        signature: <span dangerouslySetInnerHTML={{ __html: item.signature }} />,
        action: (
          <MDBox>
            <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => editCourse(index)}
            >
              Edit
            </MDTypography>
            &nbsp; &nbsp;
            {/* <MDTypography
              component="a"
              href="#"
              variant="caption"
              color="text"
              fontWeight="medium"
              onClick={() => deleteCategory(index)}
            >
              Delete
            </MDTypography> */}
          </MDBox>
        ),
      };
    }),
  };
}
