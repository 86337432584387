/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import axios from "axios";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// NewProduct page components
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import MDInput from "components/MDInput";

const buttonStyle = {
  marginLeft: "10px",
};

function PromoVideo() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/promo-video`;
  const [id, setId] = useState(null);
  const [url, setUrl] = useState(null);

  const setVideo = (path) => {
    const element = document.getElementById("embedd");
    element.innerHTML = path;
  };

  const fetchPromoVideo = () => {
    axios.get(`${baseUrl}`).then(async (res) => {
      setId(res.data.data[0].id);
      setUrl(res.data.data[0].video_url);
      setVideo(res.data.data[0].video_url);
    });
  };

  useEffect(() => {
    fetchPromoVideo();
  }, []);

  const performAction = async () => {
    setVideo(url);
    const payload = {
      id,
      video_url: url,
    };
    let actionEndPoint = "";
    actionEndPoint = `/update`;
    axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      fetchPromoVideo();
    });
  };

  const handleForm = (event) => {
    const { value } = event.target;
    setUrl(value);
  };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Promo Video
      </MDTypography>
      <Box mt={1}>
        <MDBox mt={2}>
          <MDBox mb={2}>
            <MDInput
              rows={5}
              multiline
              label="Youtube"
              value={url}
              fullWidth
              onChange={(e) => handleForm(e)}
            />
          </MDBox>

          <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
            <MDButton variant="outlined" color="info" fullWidth>
              CANCEL
            </MDButton>
            <MDButton
              style={buttonStyle}
              variant="gradient"
              color="info"
              fullWidth
              onClick={performAction}
            >
              SAVE
            </MDButton>
          </MDBox>
          <MDBox mt={2} mb={2}>
            <div id="embedd" />
          </MDBox>
        </MDBox>
      </Box>
    </MDBox>
  );
}

export default PromoVideo;
