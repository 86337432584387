/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Sales from "layouts/dashboards/sales";
// import ProfileOverview from "layouts/pages/profile/profile-overview";

// import Settings from "layouts/pages/account/settings";
import SignInBasic from "layouts/authentication/sign-in/basic";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-3.jpg";
import Course from "layouts/accademic/course";
import Package from "layouts/accademic/packages";
import Batches from "layouts/accademic/batches";
import Category from "layouts/accademic/category";
// import Users from "layouts/applications/users";
// import Roles from "layouts/applications/roles";
import Documents from "layouts/accademic/documents";
import Orders from "layouts/accademic/orders";
// import TransactionLogs from "layouts/accademic/transactions";
import Students from "layouts/students/students";
import Landing from "layouts/cms-pages/landing-page";
import About from "layouts/cms-pages/about-page";
import GoverningBoard from "layouts/cms-pages/governing-body";
import AntiRagging from "layouts/cms-pages/anti-ragging";
import GrievancePage from "layouts/cms-pages/grievance";
import MetaTags from "layouts/cms-pages/meta-tags";
import AdmissionPage from "layouts/cms-pages/admission-page";
import MissionVision from "layouts/cms-pages/mission-vission/mission-vision";
import WhyEuroTech from "layouts/cms-pages/why-eurotech";
import ImageGallery from "layouts/page-components/image-gallery";
import Awards from "layouts/cms-pages/awards";
import FromTheDeskPrincipal from "layouts/cms-pages/from-the-desk-pricipal";
import Testimonials from "layouts/page-components/testimonials";
import Affliations from "layouts/page-components/affliations";
import NewsAndEvents from "layouts/page-components/news-events";
import TermsAndConditions from "layouts/cms-pages/tnc";
import BatchManagement from "layouts/accademic/batch-management";
import EmailSettings from "./layouts/page-components/email-settings";

const routes = [
  {
    type: "collapse",
    name: localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails")).userName
      : "Admin",
    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      // {
      //   name: "My Profile",
      //   key: "my-profile",
      //   route: "/pages/profile/profile-overview",
      //   component: <ProfileOverview />,
      // },
      // {
      //   name: "Settings",
      //   key: "profile-settings",
      //   route: "/pages/account/settings",
      //   component: <Settings />,
      // },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/sign-in/basic",
        component: <SignInBasic mode="LOGOUT" />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Overview",
        key: "overview",
        route: "/dashboards/overview",
        component: <Sales />,
      },
    ],
  },
  { type: "title", title: "Accademic", key: "title-accademic" },

  {
    type: "collapse",
    name: "Courses",
    key: "courses",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Course",
        key: "course",
        route: "/course",
        component: <Course />,
      },
      {
        name: "Package",
        key: "package",
        route: "/package",
        component: <Package />,
      },
      {
        name: "Batches",
        key: "batch",
        route: "/course/batch",
        component: <Batches />,
      },
      {
        name: "Category",
        key: "category",
        route: "/course/category",
        component: <Category />,
      },
      {
        name: "Documents",
        key: "documents",
        route: "/course/documents",
        component: <Documents />,
      },
    ],
  },
  {
    type: "collapse",
    name: "Candidates Management",
    key: "candidate-management",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Candidates",
        key: "candidates",
        route: "/candidates",
        component: <Students />,
      },
      {
        name: "Orders",
        key: "orders",
        route: "/student/orders",
        component: <Orders />,
      },
      {
        name: "Batch Management",
        key: "batch-management",
        route: "/student/batch-management",
        component: <BatchManagement />,
      },
    ],
  },
  { type: "title", title: "Application", key: "title-c,s-pages" },
  {
    type: "collapse",
    name: "Page Components",
    key: "components",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Gallery",
        key: "gallery",
        route: "/gallery",
        component: <ImageGallery />,
      },
      {
        name: "Testimonials",
        key: "testimonials",
        route: "/testimonials",
        component: <Testimonials />,
      },
      {
        name: "Affliations",
        key: "affliations",
        route: "/affliations",
        component: <Affliations />,
      },
      {
        name: "News & Events",
        key: "news",
        route: "/news",
        component: <NewsAndEvents />,
      },
      {
        name: "Email Settings",
        key: "email-settings",
        route: "/email-settings",
        component: <EmailSettings />,
      },
    ],
  },
  {
    type: "collapse",
    name: "CMS Pages",
    key: "cms",
    icon: <Icon fontSize="medium">apps</Icon>,
    collapse: [
      {
        name: "Landing Page",
        key: "landing",
        route: "/landing",
        component: <Landing />,
      },
      {
        name: "About Page",
        key: "about",
        route: "/about",
        component: <About />,
      },
      {
        name: "Governing Board",
        key: "governing-board",
        route: "/governing-board",
        component: <GoverningBoard />,
      },
      {
        name: "Anti Ragging Policy",
        key: "anti-ragging-policy",
        route: "/anti-ragging-policy-and-reporting",
        component: <AntiRagging />,
      },
      {
        name: "Grievance Form List",
        key: "grievance-form-list",
        route: "/grievances",
        component: <GrievancePage />,
      },
      {
        name: "Admission List",
        key: "admission-form-list",
        route: "/admission",
        component: <AdmissionPage />,
      },
      {
        name: "Mission & Vision",
        key: "mission-vision",
        route: "/mission-vision",
        component: <MissionVision />,
      },
      {
        name: "Why EuroTech",
        key: "why-euro",
        route: "/why-euro",
        component: <WhyEuroTech />,
      },
      {
        name: "Awards",
        key: "awards",
        route: "/awards",
        component: <Awards />,
      },
      {
        name: "Meta tags",
        key: "meta-tags-list",
        route: "/meta-tags",
        component: <MetaTags />,
      },
      {
        name: "From the desk of Pricipal",
        key: "from-principlal",
        route: "/from-principlal",
        component: <FromTheDeskPrincipal />,
      },
      {
        name: "Terms and Conditions",
        key: "tnc",
        route: "/tnc",
        component: <TermsAndConditions />,
      },
    ],
  },
];

export default routes;
