/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBadge from "components/MDBadge";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Icon from "@mui/material/Icon";
import UtilityServices from "services/utility";

export default function data(tableData, candidates, callback, callback2, callback3) {
  // const editCourse = (index) => {
  //   callback(index);
  // };

  const editorders = (item) => {
    callback2(item);
  };

  const returnStatusText = (status) => {
    let retVal;
    switch (status) {
      case "captured":
        retVal = "Payment Acepted";
        break;
      case "awaiting":
        retVal = "Awaiting Payment";
        break;
      case "verified":
        retVal = "Payment Verified";
        break;
      case "completed":
        retVal = "Order Completed";
        break;
      case "refund":
        retVal = "Refund";
        break;
      case "refund_completed":
        retVal = "Refund Completed";
        break;
      case "new_batch":
        retVal = "New Batch";
        break;
      default:
        retVal = "Payment Acepted";
        break;
    }
    return retVal;
  };

  const returnStatusColor = (status) => {
    let retVal;
    switch (status) {
      case "captured":
        retVal = "info";
        break;
      case "awaiting":
        retVal = "warning";
        break;
      case "verified":
        retVal = "success";
        break;
      case "completed":
        retVal = "success";
        break;
      case "refund":
        retVal = "warning";
        break;
      case "refund_completed":
        retVal = "info";
        break;
      case "new_batch":
        retVal = "warning";
        break;
      default:
        retVal = "info";
        break;
    }
    return retVal;
  };

  const viewCandidate = (item) => {
    const selectedItem = tableData[item];
    const selectedCandidate =
      candidates &&
      candidates.length > 0 &&
      candidates.filter((val) => val.id === selectedItem.candidate_id);
    callback3(
      selectedCandidate[0],
      selectedItem.course_id,
      selectedItem.id,
      selectedItem.candidate_status_id
    );
  };

  return {
    cols: [
      { Header: "student details", accessor: "studentDetails", width: "10%", align: "left" },
      // { Header: "email", accessor: "email", width: "15%", align: "left" },
      { Header: "indos number", accessor: "indosNumber", width: "25%", align: "left" },
      { Header: "Order Id and Date", accessor: "orderIdDate", width: "15%", align: "left" },
      {
        Header: "Requested Batch",
        accessor: "batchName",
        width: "20%",
        align: "left",
      },
      {
        Header: "Course Name",
        accessor: "courseName",
        width: "25%",
        align: "left",
      },
      { Header: "paid amount", accessor: "paidAmount", align: "left" },
      { Header: "candidate status", accessor: "candidateStatus", width: "10%", align: "center" },
      { Header: "payment status", accessor: "paymentStatus", align: "center" },
      { Header: "action", accessor: "action", width: "10%", align: "left" },
    ],

    rws: tableData.map((item, index) => ({
      studentDetails: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.full_name}
          <br />
          {item.phone}
          <br />
          {item.email}
        </MDTypography>
      ),
      // email: (
      //   <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
      //     {item.email}
      //   </MDTypography>
      // ),
      indosNumber: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.indos_number}
        </MDTypography>
      ),
      orderIdDate: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.order_id}
          <br />
          {UtilityServices.formatDate(item?.date, "DD/MM/YYYY")}
        </MDTypography>
      ),
      batchName: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.Batchname} <br /> ({UtilityServices.formatDate(item?.batchStartDate, "DD/MM/YYYY")}{" "}
          - {UtilityServices.formatDate(item?.batchEndDate, "DD/MM/YYYY")} )
        </MDTypography>
      ),
      courseName: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.coursename}
        </MDTypography>
      ),
      paidAmount: (
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
          {item.full_fees}
        </MDTypography>
      ),
      candidateStatus: (
        <MDBox>
          <MDBadge
            badgeContent={item.candidate_status}
            color={item.candidate_status_color}
            variant="gradient"
            size="sm"
          />
        </MDBox>
      ),
      paymentStatus: (
        <MDBox>
          <MDBadge
            // badgeContent={item.status === "captured" ? "payment accepted" : "failed"}
            badgeContent={returnStatusText(item.status)}
            color={returnStatusColor(item.status)}
            variant="gradient"
            size="sm"
          />

          {/* <MDBadge
              badgeContent={item.payment_status}
              color={item.color}
              variant="gradient"
              size="sm"
            /> */}
        </MDBox>
      ),
      action: (
        <MDBox>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => viewCandidate(index)}
          >
            <Icon>person</Icon>
          </MDTypography>
          &nbsp; &nbsp;
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => editorders(item)}
          >
            <Icon>edit</Icon>
          </MDTypography>
        </MDBox>
      ),
    })),

    // rws: [
    //   {
    //     name: <Author image={course1} name="Basic Refresher Course" email="Post Sea Course" />,
    //     duration: <Job title="1 Day" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: (
    //       <Author
    //         image={course2}
    //         name="Basic Refresher Course"
    //         email="Personal Survival Techniques(PST)"
    //       />
    //     ),
    //     duration: <Job title="1 Day" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: <Author image={course3} name="Competency Course" email="2Nd Mate Function" />,
    //     duration: <Job title="4 Months" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: (
    //       <Author
    //         image={course4}
    //         name="Competency Course"
    //         email="Advance Shipboard Management (ASM)"
    //       />
    //     ),
    //     duration: <Job title="1 Month, 16 Days" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: <Author image={course5} name="Pre-Sea Course" email="B Tech in Marine Engineering" />,
    //     duration: <Job title="4 Years, 6 Months" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="active" color="success" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    //   {
    //     name: (
    //       <Author
    //         image={course6}
    //         name="Pre-Sea Course"
    //         email="Certificate Course For Maritime Caterging"
    //       />
    //     ),
    //     duration: <Job title="1 Year" description="" />,
    //     status: (
    //       <MDBox ml={-1}>
    //         <MDBadge badgeContent="inactive" color="warning" variant="gradient" size="sm" />
    //       </MDBox>
    //     ),
    //     date: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         01/05/23
    //       </MDTypography>
    //     ),
    //     action: (
    //       <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
    //         Edit
    //       </MDTypography>
    //     ),
    //   },
    // ],
  };
}
