/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

let tableData = null;
const buttonStyle = {
  marginLeft: "10px",
};

function WhyEuroTech() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/why-eurotech`;
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleForm = (event, key) => {
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "TITLE":
        setTitle(value);
        break;
      case "DESC":
        if (value) {
          setDescription(event);
        }
        break;
      default:
        setTitle(value);
        break;
    }
  };

  const fetchAbout = () => {
    fetch(baseUrl)
      .then((results) => results.json())
      .then(async (data) => {
        // await setTableData(data.resultMessage.data);
        tableData = data.data;
        if (data) {
          console.log(tableData);
          setTitle(tableData[0].title);
          setDescription(tableData[0].description);
        }
      });
  };

  useEffect(() => {
    fetchAbout();
  }, []);

  const performAction = async () => {
    const payload = {
      id: tableData[0].id,
      title,
      description,
    };
    let actionEndPoint = "";

    actionEndPoint = `/update`;
    axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      fetchAbout();
    });
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(payload),
    // };
    // console.log(payload);

    // const response = await fetch(`${baseUrl}${actionEndPoint}`, requestOptions);
    // const data = await response.json();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px" }}>
        <MDBox>
          <MDTypography variant="h5" fontWeight="bold">
            Why Eurotech
          </MDTypography>
          <MDBox mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDInput
                  type="type"
                  label="Title"
                  value={title}
                  fullWidth
                  onChange={(e) => handleForm(e, "TITLE")}
                />
              </Grid>
              <Grid item xs={12}>
                <MDEditor value={description} onChange={(e) => setDescription(e)} />
              </Grid>
            </Grid>
            <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
              <MDButton variant="outlined" color="info" fullWidth>
                CANCEL
              </MDButton>
              <MDButton
                style={buttonStyle}
                variant="gradient"
                color="info"
                fullWidth
                onClick={performAction}
              >
                SAVE
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default WhyEuroTech;
