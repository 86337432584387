/* eslint-disable no-use-before-define */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import "./table.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// import MDButton from "components/MDButton";

// Data
import React, { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import {
  Alert,
  Autocomplete,
  Dialog,
  Icon,
  // Divider,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
// import FormField from "layouts/applications/wizard/components/FormField";
// import PromptModal from "components/PromptModal";
// import DocumentLists from "layouts/students/students/components/DocumentLists";
// import PlatformSettings from "layouts/students/students/profile-overview/components/PlatformSettings";
// import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
// import Header from "layouts/students/students/components/Header";
import FormField from "../../applications/wizard/components/FormField";
import authorsTableData from "./data/authorsTableData";
import BatchManagementProfile from "./components/Profile";
import BatchManangementDocuments from "./components/Documents";
import BatchManagementApproval from "./components/Approval";

// import { DesktopDatePicker } from "@mui/x-date-pickers";
// import { TextField } from "@mui/material";
// import projectsTableData from "layouts/tables/data/projectsTableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: 700,
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

// const sampleCategory = [
//   {
//     id: 1,
//     name: "Parent Category 1",
//   },
//   { id: 2, name: "Parent Category 2" },
// ];

let tableData = null;
let candidateTableData = null;
let orderStateData = null;
let nameSearchText = "";
let courseSearchText = "";
let batchSearchText = "";

let masterBatches = [];
function BatchManagement() {
  const candidateBaseUrl = `${process.env.REACT_APP_BASE_URL}/candidate`;
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/courses`;
  const orderUrl = `${process.env.REACT_APP_BASE_URL}/orders`;
  const batchUrl = `${process.env.REACT_APP_BASE_URL}/batches`;
  const [open, setOpen] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [courses, setCourses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [courseBatches, setCourseBatches] = useState([]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [remarks, setRemarks] = useState("");
  const [orderId, setOrderId] = useState(0);
  const [orderState, setOrderState] = useState([]);
  const [currentOrderStatus, setCurrentOrderStatus] = useState("captured");
  const [newOrderStatus, setNewOrderStatus] = useState(0);
  const [searchName, setSearchName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchCourse, setSearchCourse] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [searchBatch, setSearchBatch] = useState("");

  const [currentBatch, setCurrentBatch] = useState();
  // eslint-disable-next-line no-unused-vars
  const [newBatch, setNewBatch] = useState(0);

  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [selectedOrder, setSelectedOrder] = useState("");
  const [candidateStatus, setCandidateStatus] = useState([]);
  const [alertText, setAlertText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("success");

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDialogClose = () => setOpenAssign(false);
  // stepper
  function getSteps() {
    return ["Profile", "Documents", "Approval"];
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return <BatchManagementProfile candidate={selectedCandidate} />;
      case 1:
        return <BatchManangementDocuments documents={selectedDocuments} orderId={orderId} />;
      case 2:
        return (
          <BatchManagementApproval
            candidate={selectedCandidate}
            candidateStatus={candidateStatus}
            update={updateCandidateStatus}
          />
        );
      default:
        return null;
    }
  }

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const isLastStep = activeStep === steps.length - 1;

  const setForm = (data) => {
    setOrderId(data?.id);
    setRemarks(data?.description);
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = (index) => {
    if (tableData?.length > 0) {
      setSelectedOrder(tableData[index]);
      setForm(tableData[index]);
      setOpenAssign(true);
    }
    setButtonTitle("UPDATE");
    console.log(tableData);
    console.log(selectedOrder);
    // handleOpen();
  };

  const setCandiateIndex = (candidateItem, courseId, orderedCourseId, candidateId) => {
    if (candidateTableData?.length > 0) {
      setActiveStep(0);
      setCandidateForm(candidateItem, courseId);
      // eslint-disable-next-line no-param-reassign
      candidateItem.orderedCourseId = orderedCourseId;
      // eslint-disable-next-line no-param-reassign
      candidateItem.currentCandidateStatusId = candidateId;
      setSelectedCandidate(candidateItem);
    }
    setOpenAssign(true);
  };

  const getOrderStatus = (status) => orderStateData.filter((item) => item.title === status);

  // eslint-disable-next-line no-undef
  const editItem = async (order) => {
    // setModalActionTitle("UPDATE");
    if (tableData?.length > 0) {
      setForm(order);
      setOrderId(order.id);
    }
    const status = getOrderStatus(order.status ? order.status : "awaiting");
    setCurrentOrderStatus(status && status.length > 0 ? status[0].title : orderState[1].title);
    const cuBatch = masterBatches.filter((item) => item.id === order.batchId);
    setCurrentBatch(cuBatch);
    const availBatch = await axios.get(`${batchUrl}/course/${order.course_id}`);
    setCourseBatches(availBatch?.data?.resultMessage?.data);
    console.log(status);
    setButtonTitle("UPDATE");
    handleOpen();
  };

  const clearForm = () => {
    setRemarks("");
  };

  const fetchCandidateCourse = (candidateId, courseId) => {
    axios.get(`${baseUrl}/student/order/${candidateId}`).then((res) => {
      if (res && res.data && res.data.resultMessage && res.data.resultMessage.data) {
        const c = res.data.resultMessage.data;
        // eslint-disable-next-line no-unused-vars
        const selectedCourse = c && c.length > 0 && c.filter((item) => item.course_id === courseId);
        setSelectedCourses(selectedCourse);
      }
    });
  };

  const fetchCandidateDocuments = (candidateId) => {
    axios.get(`${baseUrl}/student/documents/${candidateId}`).then((res) => {
      if (res && res.data && res.data.resultMessage && res.data.resultMessage.data) {
        setSelectedDocuments(res.data.resultMessage.data);
      }
    });
  };
  const fetchCourse = () => {
    axios.get(`${baseUrl}`).then((res) => {
      if (res && res.data && res.data.resultMessage && res.data.resultMessage.data) {
        // const fetchedCourses = res.data.resultMessage.data;
        setCourses(res.data.resultMessage.data);
        // fetchBatch(fetchedCourses[0].id);
        fetchBatch();
      }
    });
  };
  const fetchBatch = () => {
    axios.get(`${batchUrl}`).then((res) => {
      if (res && res.data && res.data.resultMessage && res.data.resultMessage.data) {
        setBatches(res.data.resultMessage.data);
        masterBatches = res.data.resultMessage.data;
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const setCandidateForm = async (data, courseId) => {
    fetchCandidateCourse(data.id, courseId);
    fetchCandidateDocuments(data.id);
    console.log(data);
  };

  const setTableData = (data) => {
    const { cols, rws } = authorsTableData(
      data,
      candidateTableData,
      setitemIndex,
      editItem,
      setCandiateIndex
    );
    setRows(rws);
    setColumns(cols);
  };

  const fetchOrders = (filter = false) => {
    clearForm();
    let path;
    if (!filter) {
      path = "/orders/batchmanagement-list";
      setSearchName("");
    } else {
      path = `/orders/list-batchmanagement?candidateName=${encodeURIComponent(
        nameSearchText
      )}&courseName=${encodeURIComponent(courseSearchText)}&batchName=${encodeURIComponent(
        batchSearchText
      )}`;
    }
    axios.get(`${baseUrl}${path}`).then(async (data) => {
      console.log(data);
      // await setTableData(data.resultMessage.data);
      tableData = data.data.resultMessage.data;
      setTableData(tableData);
      // const { cols, rws } = authorsTableData(
      //   data.data.resultMessage.data,
      //   candidateTableData,
      //   setitemIndex,
      //   editItem,
      //   setCandiateIndex
      // );
      // setRows(rws);
      // setColumns(cols);
    });
  };

  const fetchOrderStatus = () => {
    const path = "/status";
    axios.get(`${orderUrl}${path}`).then(async (res) => {
      setOrderState(res.data.data);
      orderStateData = res.data.data;
      console.log(orderState);
      console.log(res.data.data);
    });
  };

  const fetchCandidates = async () => {
    await fetch(`${candidateBaseUrl}`)
      .then((results) => results.json())
      .then(async (response) => {
        candidateTableData = response.data;
        fetchOrders();
      });
  };

  const fetchCandidateStatus = async () => {
    await fetch(`${baseUrl}/candidate/status`)
      .then((results) => results.json())
      // eslint-disable-next-line no-unused-vars
      .then(async (response) => {
        setCandidateStatus(response.data);
      });
  };

  useEffect(() => {
    fetchCandidates();
    // fetchOrders();
    fetchOrderStatus();
    fetchCourse();
    fetchCandidateStatus();
  }, []);

  const handleForm = (event, key) => {
    console.log(orderState);
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "REMARKS":
        if (value) {
          setRemarks(value);
        }
        break;
      default:
        setRemarks(value);
        break;
    }
  };

  const handleClearFilter = () => {
    if (nameSearchText !== "" || courseSearchText !== "" || batchSearchText !== "") {
      fetchOrders(true);
    } else {
      fetchOrders();
    }
  };

  const applyFIlter = async (searchText, type) => {
    if (type === "N") {
      const { value } = searchText.target;
      if (value.length > 0) {
        nameSearchText = value;
        setSearchName(nameSearchText === "" ? undefined : nameSearchText.toLowerCase());
        fetchOrders(true);
      } else {
        setSearchName("");
        nameSearchText = "";
        handleClearFilter();
      }
    } else if (type === "C") {
      // setBatchSearchText("");
      if (searchText.length > 0) {
        // courseSearchText = searchText;
        const courseFilter = tableData.filter(
          (candidate) => candidate.coursename.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        );
        const availBatch = await axios.get(`${batchUrl}/course/${courseFilter[0].course_id}`);
        // eslint-disable-next-line no-unused-expressions
        availBatch && setBatches(availBatch?.data?.resultMessage?.data);
        setSearchCourse(searchText);
        courseSearchText = searchText;
        setSearchCourse(searchText);
        fetchOrders(true);
      } else {
        courseSearchText = "";
        setSearchCourse("");
        setBatches(masterBatches);
        handleClearFilter();
      }
    } else if (type === "B") {
      if (searchText.length > 0) {
        setSearchBatch(searchText);
        batchSearchText = searchText;
        fetchOrders(true);
      } else {
        batchSearchText = "";
        setSearchBatch("");
        handleClearFilter();
      }
    }
    // else {
    //   // eslint-disable-next-line no-lonely-if
    //   if (searchText.length > 0) {
    //     let courseFilter = [];
    //     let batchFilter = [];
    //     let statusFilter = [];
    //     statusSearchText = searchText;
    //     if (courseSearchText.length > 0) {
    //       courseFilter = tableData.filter(
    //         (candidate) =>
    //           candidate.coursename.toLowerCase().indexOf(courseSearchText.toLowerCase()) > -1
    //       );
    //       if (batchSearchText.length > 0) {
    //         batchFilter = courseFilter.filter(
    //           (candidate) =>
    //             candidate.Batchname.toLowerCase().indexOf(batchSearchText.toLowerCase()) > -1
    //         );
    //         statusFilter = batchFilter.filter(
    //           (candidate) =>
    //             String(candidate.status).toLowerCase().indexOf(searchText.toLowerCase()) > -1
    //         );
    //       } else {
    //         statusFilter = courseFilter.filter(
    //           (candidate) =>
    //             String(candidate.status).toLowerCase().indexOf(searchText.toLowerCase()) > -1
    //         );
    //       }

    //       if (statusFilter.length > 0) {
    //         setTableData(statusFilter);
    //       } else {
    //         setTableData([]);
    //       }
    //     } else if (batchSearchText.length > 0) {
    //       batchFilter = tableData.filter(
    //         (candidate) => candidate.Batchname.toLowerCase().indexOf(searchText.toLowerCase()) > -1
    //       );
    //       statusFilter =
    //         batchFilter.length > 0 &&
    //         batchFilter.filter(
    //           (candidate) =>
    //             String(candidate.status).toLowerCase().indexOf(searchText.toLowerCase()) > -1
    //         );
    //       if (statusFilter.length > 0) {
    //         setTableData(statusFilter);
    //       } else if (batchFilter.length > 0) {
    //         setTableData(batchFilter);
    //       } else {
    //         setTableData([]);
    //       }
    //     } else {
    //       statusFilter = tableData.filter(
    //         (candidate) =>
    //           String(candidate.status).toLowerCase().indexOf(searchText.toLowerCase()) > -1
    //       );
    //       if (statusFilter.length > 0) {
    //         setTableData(statusFilter);
    //       } else {
    //         setTableData([]);
    //       }
    //     }
    //   } else {
    //     statusSearchText = "";
    //     if (courseSearchText.length > 0) {
    //       applyFIlter(courseSearchText, "C");
    //     } else if (batchSearchText.length > 0) {
    //       applyFIlter(batchSearchText, "B");
    //     } else {
    //       setTableData(tableData);
    //     }
    //   }
    // }
  };

  const generateAndDownloadReport = () => {
    axios({
      url: `${baseUrl}/orders/batchmanagement-download?candidateName=${encodeURIComponent(
        nameSearchText
      )}&courseName=${encodeURIComponent(courseSearchText)}&batchName=${encodeURIComponent(
        batchSearchText
      )}`,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "Batch_Management_List.xlsx");
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  const performAction = async () => {
    const payload = {
      id: orderId,
      payment_status: newOrderStatus,
      new_bacth_id: newBatch,
      remarks,
    };
    console.log(payload);
    const actionEndPoint = "/update";
    axios.put(`${orderUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      setOpen(false);
      fetchOrders();
    });
  };

  // eslint-disable-next-line no-shadow, no-unused-vars
  const updateCandidateStatus = (candidateStatus) => {
    const payload = {
      id: selectedCandidate.orderedCourseId,
      candidate_status: candidateStatus,
    };
    axios
      .put(`${baseUrl}/update/candidate-status/${selectedCandidate.orderedCourseId}`, payload)
      // eslint-disable-next-line no-unused-vars
      .then((response) => {
        setAlertText("Successfully Updated candidate status");
        setShowAlert(true);
        setAlertType("success");
        setOpenAssign(false);
        fetchOrders();
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="modal-window">
        {/* <PromptModal title={deleteWarning} /> */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          open={showAlert}
          message={alertText}
          severity={alertType}
          onClose={() => setShowAlert(false)}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity={alertType}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>

        <Dialog maxWidth="xl" fullWidth="true" open={openAssign} onClose={handleDialogClose}>
          <MDBox pt={3} pb={8}>
            <Grid container justifyContent="center" sx={{ my: 4 }}>
              <Grid item xs={12} lg={8}>
                {selectedCourses && selectedCourses.length > 0 ? (
                  <MDBox mt={6} mb={8} textAlign="center">
                    <MDBox mb={1}>
                      <MDTypography variant="h3" fontWeight="bold">
                        {selectedCourses[0].coursename}
                      </MDTypography>
                    </MDBox>
                    <MDTypography variant="h5" fontWeight="regular" color="secondary">
                      {selectedCourses[0].Batchname}
                    </MDTypography>
                  </MDBox>
                ) : null}

                <Card>
                  <MDBox mt={-3} mx={2}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                  <MDBox p={2}>
                    <MDBox>
                      {getStepContent(activeStep)}
                      <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton variant="outlined" color="dark" onClick={handleBack}>
                            back
                          </MDButton>
                        )}
                        <MDButton
                          variant="gradient"
                          color="dark"
                          onClick={!isLastStep ? handleNext : handleDialogClose}
                        >
                          {isLastStep ? "Done" : "next"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          {/* <Header
            candidate={selectedCandidate}
            candidateStatus={candidateStatus}
            update={updateCandidateStatus}
          />
          <MDBox mt={5} mb={3}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} xl={4}>
                <PlatformSettings courses={selectedCourses} />
              </Grid>
              <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                <ProfileInfoCard
                  title="profile information"
                  description={selectedCandidate.full_name ? selectedCandidate.full_name : ""}
                  info={{
                    fullName: selectedCandidate.full_name,
                    mobile: selectedCandidate.phone,
                    email: selectedCandidate.email,
                    DOB: selectedCandidate.date_of_birth,
                    kinName: selectedCandidate.name_of_next_of_kin,
                    kinNumber: selectedCandidate.contact_of_next_of_kin,
                  }}
                  social={[
                    {
                      link: "https://www.facebook.com/CreativeTim/",
                      icon: "",
                      color: "facebook",
                    },
                    {
                      link: "https://twitter.com/creativetim",
                      icon: "",
                      color: "twitter",
                    },
                    {
                      link: "https://www.instagram.com/creativetimofficial/",
                      icon: "",
                      color: "instagram",
                    },
                  ]}
                  action={{ route: "", tooltip: "Edit Profile" }}
                  shadow={false}
                />
                <Divider orientation="vertical" sx={{ mx: 0 }} />
              </Grid>
              <Grid item xs={12} xl={4}>
                <DocumentLists
                  title="Uploaded Documents"
                  shadow={false}
                  documents={selectedDocuments}
                />
              </Grid>
            </Grid>
          </MDBox> */}
        </Dialog>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            {orderState.length > 0 ? (
              <MDBox mb={2}>
                <MDInput
                  type="type"
                  label="Current Status"
                  value={
                    orderState.filter((item) => item.title === currentOrderStatus)[0].description
                  }
                  fullWidth
                  disabled
                />
              </MDBox>
            ) : null}
            {orderState.length > 0 ? (
              <MDBox mb={2}>
                <Autocomplete
                  defaultValue={orderState.filter((item) => item.title === currentOrderStatus)[0]}
                  // defaultValue={currentOrderStatus}
                  options={orderState}
                  getOptionLabel={(option) => option.description}
                  onChange={(e, v) => setNewOrderStatus(v.title)}
                  renderInput={(params) => (
                    <FormField {...params} label="New Status" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </MDBox>
            ) : null}

            <MDBox mb={2}>
              <MDBox mb={2}>
                <MDInput
                  type="type"
                  label="Requested Batch"
                  value={currentBatch && currentBatch[0]?.title}
                  fullWidth
                  disabled
                />
              </MDBox>
            </MDBox>
            <MDBox mb={2}>
              <Autocomplete
                // defaultValue={batches.filter((item) => item.title === currentOrderStatus)[0]}
                // defaultValue={batches[0]}
                defaultValue={modalActionTitle === "UPDATE" ? currentBatch[0] : courseBatches[0]}
                options={courseBatches}
                getOptionLabel={(option) => option.title}
                onChange={(e, v) => setNewBatch(v.id)}
                renderInput={(params) => (
                  <FormField {...params} label="New Batch" InputLabelProps={{ shrink: true }} />
                )}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                label="Remarks"
                multiline
                value={remarks}
                rows={5}
                fullWidth
                onChange={(e) => handleForm(e, "REMARKS")}
              />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="batch-management-button-wrap">
                <MDBox ml={2} mt={2} width={300}>
                  <MDInput
                    type="type"
                    label="Search by Candidate Name"
                    fullWidth
                    value={searchName}
                    onChange={(e) => applyFIlter(e, "N")}
                  />
                </MDBox>
                <MDBox ml={2} mt={2} width={350}>
                  <Autocomplete
                    defaultValue={courses[0]}
                    options={courses}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, v, reason) => {
                      // eslint-disable-next-line no-unused-expressions
                      reason === "clear" ? applyFIlter("", "C") : applyFIlter(v.title, "C");
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Filter by Course"
                      />
                    )}
                  />
                </MDBox>
                <MDBox ml={2} mt={2} width={250}>
                  <Autocomplete
                    defaultValue={batches[0]}
                    options={batches}
                    getOptionLabel={(option) => option.title}
                    onChange={(e, v, reason) => {
                      // eslint-disable-next-line no-unused-expressions
                      reason === "clear" ? applyFIlter("", "B") : applyFIlter(v.title, "B");
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Filter by Batch"
                      />
                    )}
                  />
                </MDBox>
                {/* <MDBox ml={2} mt={2} mr={3} width={250}>
                  <Autocomplete
                    defaultValue={orderState.filter((item) => item.title === currentOrderStatus)[0]}
                    options={orderState}
                    getOptionLabel={(option) => option.description}
                    onChange={(e, v, reason) => {
                      // eslint-disable-next-line no-unused-expressions
                      reason === "clear" ? applyFIlter("", "S") : applyFIlter(v.title, "S");
                    }}
                    renderInput={(params) => (
                      <FormField
                        {...params}
                        InputLabelProps={{ shrink: true }}
                        label="Filter by Status"
                      />
                    )}
                  />
                </MDBox> */}
                <MDBox ml={2} mt={2} mr={3} width={80}>
                  <MDButton color="dark" onClick={generateAndDownloadReport}>
                    <Icon>download</Icon>
                  </MDButton>
                </MDBox>
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Batch Management
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage
                    pagination
                    showTotalEntries
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default BatchManagement;
