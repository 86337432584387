/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { useEffect, useState } from "react";

const buttonStyle = {
  marginLeft: "10px",
};

function TermsAndConditions() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/tnc`;
  const [id, setId] = useState("");
  const [tnc, setTnc] = useState("");
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [disclaimer, setDisclaimer] = useState("");

  const handleForm = (value, key) => {
    switch (key) {
      case "TNC":
        setTnc(value);
        break;
      case "PVC":
        setPrivacyPolicy(value);
        break;
      case "DISC":
        setDisclaimer(value);
        break;
      default:
        setTnc(value);
        break;
    }
  };

  const fetchTermsAndConditions = () => {
    fetch(baseUrl)
      .then((results) => results.json())
      .then(async (data) => {
        // await setTableData(data.resultMessage.data);
        if (data) {
          setId(data.data[0].id);
          setTnc(data.data[0].tnc);
          setDisclaimer(data.data[0].disclaimer);
          setPrivacyPolicy(data.data[0].privacy);
        }
      });
  };

  useEffect(() => {
    fetchTermsAndConditions();
  }, []);

  const performAction = async () => {
    const payload = {
      id,
      tnc,
      privacy: privacyPolicy,
      disclaimer,
    };
    let actionEndPoint = "";

    actionEndPoint = `/update`;
    axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      fetchTermsAndConditions();
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px" }}>
        <MDBox>
          <MDBox mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDTypography variant="h5" fontWeight="bold">
                  Terms and Conditions
                </MDTypography>
                <MDEditor value={tnc} onChange={(e) => handleForm(e, "TNC")} />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h5" fontWeight="bold">
                  Privacy Policy
                </MDTypography>
                <MDEditor value={privacyPolicy} onChange={(e) => handleForm(e, "PVC")} />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h5" fontWeight="bold">
                  Disclaimer
                </MDTypography>
                <MDEditor value={disclaimer} onChange={(e) => handleForm(e, "DISC")} />
              </Grid>
            </Grid>
            <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
              <MDButton variant="outlined" color="info" fullWidth>
                CANCEL
              </MDButton>
              <MDButton
                style={buttonStyle}
                variant="gradient"
                color="info"
                fullWidth
                onClick={performAction}
              >
                SAVE
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default TermsAndConditions;
