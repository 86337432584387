/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import "./course.css";
// import DatePicker from "react-date-picker";
import AWS from "aws-sdk";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import moment from "moment-timezone";
// import MDButton from "components/MDButton";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
import {
  Alert,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Snackbar,
  Switch,
  Typography,
} from "@mui/material";
// import MDDatePicker from "components/MDDatePicker";
import FormField from "layouts/applications/wizard/components/FormField";
import MDDatePicker from "components/MDDatePicker";
import CustomEditor from "components/Editor/CustomEditor";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};
const alertStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "hidden",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

let tableData = null;
let msterDocuments = [];
const signedUrl = "";

AWS.config.update({
  accessKeyId: "AKIAU6GDZZFUMBPCAAQI",
  secretAccessKey: "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",
  region: "ap-south-1",
  signatureVersion: "v4",
});

function Course() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}`;
  const s3 = new AWS.S3();
  const [imageUrl, setImageUrl] = useState(null);
  const [brochureUrl, setBrochureUrl] = useState(null);
  // const { columns: pColumns, rows: pRows } = projectsTableData();
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  // const [action, setAction] = useState("ADD");
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  // const [tableData, setTableData] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [name, setName] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [category, setCategory] = useState("");
  const [slug, setSlug] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [categoryId, setCategoryId] = useState(0);
  const [partialPayment, setPartialPayment] = useState(false);
  const [partialPay, setPartialPay] = useState("");
  const [fees, setFees] = useState("");
  const [duration, setDuration] = useState("");
  const [eligibility, setEligibility] = useState("<h1>Write description here...</h1>");
  const [description, setDescription] = useState("");
  // const [documentsRequired, setDocumentsRequired] = useState("");
  const [message, setMessage] = useState("");
  const [tnc, setTnc] = useState("");
  const [link, setLink] = useState("");
  const [documents, setDocuments] = useState([]);

  const [image, setImage] = useState({ preview: "", data: "" });
  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [courseId, setCourseId] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [alertText, setAlertText] = useState("Success");
  const [alertType, setAlertType] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDelete = () => setDeleteOpen(true);
  const handleDeleteClose = () => setDeleteOpen(false);

  const setDocumentStatus = (data) => {
    if (data && data.length > 0) {
      const tempDoc = JSON.parse(JSON.stringify(msterDocuments));

      if (tempDoc.length > 0) {
        tempDoc.forEach((item, index) => {
          data.forEach((doc) => {
            if (doc.id === item.id) {
              tempDoc[index].checked = doc.checked;
            }
          });
        });
      }
      // console.log(tempDoc);
      // setDocuments(tempDoc);
    }
  };

  const setForm = (data) => {
    setIsActive(data && data.is_active);
    setName(data ? data?.title : "");
    setSlug(data ? data?.slug : "");
    setMetaTitle(data ? data?.meta_title : "");
    setMetaDescription(data ? data?.meta_description : "");
    setPartialPayment(data && data.is_partial_payment);
    setPartialPay(data && data.partial_pay);
    setDescription(data ? data?.description : "");
    setCategoryId(data?.category_id);
    setCategory(data ? data?.category_id : categoryList[0].id);
    // setStartDate(data ? data?.updated_at.split("T")[0].replaceAll("-", "/") : "");
    setStartDate(data ? data?.date : "");
    setDuration(data ? data?.duration : "");
    setFees(data ? data?.fees : "");
    setEligibility(data ? data?.eligibility : "");
    // setDocumentsRequired(data ? data?.documents_required : "");
    if (data && data.documents_required.length > 0) {
      setDocumentStatus(JSON.parse(data.documents_required));
    } else {
      setDocuments(msterDocuments);
    }
    setMessage(data ? data?.message : "");
    setTnc(data ? data?.tnc : "");
    setLink(data ? data?.link : "");
    setImageUrl(data ? data?.cover_image_url : "");
    setImage(data ? data?.cover_image_url : "");
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = (course, mode) => {
    // setModalActionTitle("UPDATE");
    setCourseId(course.id);
    if (mode === "EDIT") {
      setButtonTitle("UPDATE");
      if (tableData?.length > 0) {
        setDocuments(msterDocuments);
        setForm(course);
      }
      handleOpen();
    } else {
      setButtonTitle("DELETE");
      handleDelete();
    }
  };

  const setTableData = (data) => {
    const { cols, rws } = authorsTableData(data, setitemIndex);
    // console.log(rws);
    setRows(rws);
    setColumns(cols);
  };

  const fetchCourses = () => {
    axios.get(`${baseUrl}/courses`).then(async (data) => {
      // await setTableData(data.resultMessage.data);
      tableData = data.data.resultMessage.data;
      setTableData(data.data.resultMessage.data);
    });
  };

  const fetchApplicationDocs = () => {
    axios.get(`${baseUrl}/documents`).then(async (data) => {
      msterDocuments = [...data.data.resultMessage.data];
      const tempDocs = [...msterDocuments];
      data.data.resultMessage.data.forEach((item, index) => {
        if (item.checked) {
          tempDocs[index].checked = true;
        } else {
          tempDocs[index].checked = false;
        }
      });
      setDocuments(tempDocs);
    });
  };

  const searchCourse = (event) => {
    const searchText = event.target.value;
    if (searchText.length > 0) {
      const filtered = tableData.filter(
        (course) => course.title.toLowerCase().indexOf(searchText) > -1
      );
      setTableData(filtered);
    } else {
      setTableData(tableData);
    }
  };

  const fetchCategory = () => {
    axios.get(`${baseUrl}/category`).then(async (data) => {
      setCategoryList(data?.data.resultMessage?.data);
    });
  };

  useEffect(() => {
    fetchCourses();
    fetchApplicationDocs();
    fetchCategory();
  }, []);

  const addCourse = () => {
    setForm(null);
    setDocumentStatus();
    setIsActive(true);
    setModalActionTitle("ADD");
    handleOpen();
  };

  const handlePartialPayment = () => {
    setPartialPayment(!partialPayment);
  };

  const handleForm = (event, key) => {
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "CTITLE":
        setName(value);
        break;
      case "SLUG":
        setSlug(value);
        break;
      case "META_TITLE":
        setMetaTitle(value);
        break;
      case "META_DESCRIPTION":
        setMetaDescription(value);
        break;
      case "CCATEGORY":
        setCategory(value);
        break;
      case "DESCRIPTION":
        setDescription(value);
        break;
      case "DATE":
        if (value) {
          setStartDate(value);
        }
        break;
      case "PPAY":
        setPartialPay(value);
        break;
      case "FEES":
        setFees(value);
        break;
      case "DURATION":
        setDuration(value);
        break;
      case "ELIGIBILITY":
        setEligibility(value);
        break;
      case "MESSAGE":
        setMessage(value);
        break;
      case "T&C":
        setTnc(value);
        break;
      case "ADDLINK":
        setLink(value);
        break;
      default:
        setName(value);
        break;
    }
  };

  const documentSelected = (event, id) => {
    const tempDocuments = [...documents];
    documents.forEach((item, index) => {
      if (item.id === id) {
        tempDocuments[index].checked = event.target.checked;
      }
    });
    setDocuments(tempDocuments);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const params = {
      Bucket: "eurocourse-images",
      Key: `${Date.now()}.${file.name}`,
      Body: file,
    };

    const { Location } = await s3.upload(params).promise();
    setImageUrl(Location);
    console.log("uploading to s3", Location);
    // console.log("uploading to s3", imageUrl);

    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setImage(img);
    // console.log(signedUrl);
    // console.log(image);
  };

  const handleBrochureFileChange = async (e) => {
    const file = e.target.files[0];

    const params = {
      Bucket: "eurocourse-brochures",
      Key: `${Date.now()}.${file.name}`,
      Body: file,
    };
    const { Location } = await s3.upload(params).promise();
    setBrochureUrl(Location);
  };

  const handleDateChange = (e) => {
    // setStartDate(e && e.length > 0 ? e[0].toLocaleDateString().split("/").reverse().join("-") : e);
    // eslint-disable-next-line no-unused-vars
    if (e && e.length > 0) {
      setStartDate(
        modalActionTitle === "ADD "
          ? moment(e[0].toISOString()).add(1, "days").format("YYYY-MM-DD")
          : moment(e[0].toISOString()).format("YYYY-MM-DD")
      );
    }
  };

  const initiateUpload = async () => {
    // const body = new FormData();
    // body.append("file", image.data);
    // await fetch(signedUrl, { method: "PUT", body }).then((response) => {
    //   console.log(response);
    // });
    // const resp = await fetch(signedUrl.url, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": file[0].type,
    //   },
    //   body: file[0],
    // });
    // console.log("resp", resp);
  };

  const performAction = async (action = "UPDATE") => {
    if (image) {
      initiateUpload();
    }
    const payload = {
      is_active: isActive,
      title: name,
      slug,
      meta_title: metaTitle,
      meta_description: metaDescription,
      category_id: category,
      description,
      is_partial_payment: partialPayment,
      partial_pay: partialPay,
      eligibility,
      documents_required: JSON.stringify(documents),
      message,
      tnc,
      link,
      cover_image_url: imageUrl,
      course_brochure_url: brochureUrl,
      fees,
      duration,
      created_by: 101,
      created_at: new Date(startDate).toISOString().slice(0, 19).replace("T", " "),
      updated_at: new Date(startDate).toISOString().slice(0, 19).replace("T", " "),
      date: new Date(startDate).toISOString().slice(0, 19).replace("T", " "),
    };
    let actionEndPoint = "";
    if (action === "ADD") {
      actionEndPoint = "/courses/add";
      axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        setAlertType("success");
        setAlertText(response.data.resultMessage.msgs);
        setOpen(false);
        fetchCourses();
        setShowAlert(true);
      });
      setOpen(false);
    } else if (action === "UPDATE") {
      actionEndPoint = `/courses/update/${courseId}`;
      axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        setAlertType("success");
        setAlertText(response.data.resultMessage.msgs);
        setOpen(false);
        fetchCourses();
        setShowAlert(true);
      });
      setOpen(false);
    } else if (action === "DELETE") {
      actionEndPoint = `/courses/delete/${courseId}`;
      axios.delete(`${baseUrl}${actionEndPoint}`).then((response) => {
        if (response.data.resultCode === "201") {
          setAlertType("error");
        } else {
          setAlertType("success");
        }
        setAlertText(response.data.resultMessage.msg);
        fetchCourses();
        setOpen(false);
        setDeleteOpen(false);
        setShowAlert(true);
      });
      setOpen(false);
    }

    // setTimeout(() => {
    //   fetchCourses();
    // }, 500);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="modal-window">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          open={showAlert}
          message={alertText}
          severity={alertType}
          onClose={() => setShowAlert(false)}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity={alertType}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={deleteOpen}
          onClose={handleDeleteClose}
          closeAfterTransition
          slots={{
            backdrop: {
              component: Backdrop,
              props: {
                timeout: 500,
              },
            },
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={alertStyle}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Do you want to delete the selected Course ?
            </Typography>
            <div className="prompt-button-box">
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => performAction(modalActionTitle)}
                >
                  {modalActionTitle}
                </MDButton>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  fullWidth
                  onClick={() => handleDeleteClose()}
                >
                  CANCEL
                </MDButton>
              </MDBox>
            </div>
          </Box>
        </Modal>
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}

          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mt={0.5}>
              <MDTypography variant="button" color="text">
                Active
              </MDTypography>
              <Switch checked={isActive} onChange={() => setIsActive(!isActive)} />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                type="type"
                value={name}
                label="Course Title"
                fullWidth
                onChange={(e) => handleForm(e, "CTITLE")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="type"
                value={slug}
                label="Course Slug"
                fullWidth
                onChange={(e) => handleForm(e, "SLUG")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="type"
                value={metaTitle}
                label="Meta Title"
                fullWidth
                onChange={(e) => handleForm(e, "META_TITLE")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="type"
                value={metaDescription}
                label="Meta Description"
                fullWidth
                onChange={(e) => handleForm(e, "META_DESCRIPTION")}
              />
            </MDBox>
            <MDBox mb={2}>
              <Autocomplete
                defaultValue={
                  modalActionTitle === "ADD"
                    ? categoryList[0]
                    : categoryList.filter((data) => data.id === categoryId)[0]
                }
                options={categoryList}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => setCategory(v.id)}
                renderInput={(params) => (
                  <FormField
                    {...params}
                    label="Course Category"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="caption" fontWeight="regular">
                Enable Partial Payment
              </MDTypography>
              <MDBox ml={1}>
                <Switch checked={partialPayment} onChange={handlePartialPayment} />
              </MDBox>
            </MDBox>
            {partialPayment ? (
              <MDBox mb={2}>
                <MDInput
                  type="type"
                  label="Partial Payment"
                  value={partialPay}
                  fullWidth
                  onChange={(e) => handleForm(e, "PPAY")}
                />
              </MDBox>
            ) : null}

            <MDBox mb={2}>
              <MDInput
                type="type"
                label="Fees"
                value={fees}
                fullWidth
                onChange={(e) => handleForm(e, "FEES")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="type"
                label="Duration"
                value={duration}
                fullWidth
                onChange={(e) => handleForm(e, "DURATION")}
              />
            </MDBox>
            <MDBox mb={2}>
              {/* <MDInput
              label="Eligibility"
              multiline
              fullWidth
              value={eligibility}
              rows={5}
              onChange={(e) => handleForm(e, "ELIGIBILITY")}
            /> */}
              <CustomEditor initialData={eligibility} setData={setEligibility} />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                label="Course Breif"
                multiline
                value={description}
                rows={5}
                fullWidth
                onChange={(e) => handleForm(e, "DESCRIPTION")}
              />
            </MDBox>

            <MDBox mb={2}>
              <MDInput
                label="Any message when choose this."
                multiline
                value={message}
                rows={5}
                fullWidth
                onChange={(e) => handleForm(e, "MESSAGE")}
              />
            </MDBox>
            {/* <MDBox mb={2}>
              <MDInput
                label="Terms and Conditions."
                multiline
                value={tnc}
                rows={5}
                fullWidth
                onChange={(e) => handleForm(e, "T&C")}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="type"
                value={link}
                label="Add link"
                fullWidth
                onChange={(e) => handleForm(e, "ADDLINK")}
              />
            </MDBox> */}

            <MDBox mb={2}>
              {/* <MDInput
              type="type"
              label="Start Date"
              fullWidth
              onChange={(e) => handleForm(e, "SDATE")}
            /> */}

              {/* <DatePicker onChange={setStartDate} value={startDate} /> */}
              {modalActionTitle === "ADD" ? (
                <MDDatePicker
                  options={{ minDate: new Date().toISOString() }}
                  onChange={(e) => handleDateChange(e)}
                  input={{ placeholder: "Select a date" }}
                />
              ) : (
                <MDDatePicker
                  options={{ minDate: new Date().toISOString() }}
                  onChange={(e) => handleDateChange(e)}
                  value={startDate}
                  input={{ placeholder: "Select a date" }}
                />
              )}

              {/* <input
              type="date"
              value={new Date(startDate).toLocaleDateString()}
              className="date-control"
              onChange={(e) => handleForm(e, "SDATE")}
            /> */}
            </MDBox>

            <MDBox mb={2}>
              {/* <MDInput
              type="type"
              label="Eligibility"
              fullWidth
              onChange={(e) => handleForm(e, "ELIGIBILITY")}
            /> */}
              <label htmlFor="course-image">
                Choose Course image :{" "}
                <input type="file" name="course-image" onChange={handleFileChange} />
              </label>
              <label htmlFor="course-brochure">
                Choose brochure :{" "}
                <input
                  type="file"
                  accept="application/pdf"
                  name="course-brochure"
                  onChange={handleBrochureFileChange}
                />
              </label>
            </MDBox>
            <MDBox mb={2}>
              <label htmlFor="course-image">
                Documents required:
                <input type="file" name="course-image" style={{ display: "none" }} />
                <div className="doc-list">
                  {documents.length > 0
                    ? documents.map((item) => (
                        <FormControlLabel
                          label={item.name}
                          key={item.id}
                          control={
                            <Checkbox
                              onChange={(e) => documentSelected(e, item.id)}
                              checked={item.checked}
                            />
                          }
                        />
                      ))
                    : null}
                </div>
              </label>
            </MDBox>
            {image ? (
              <MDBox mb={2}>
                <img alt="" className="imageBox" src={image} />
              </MDBox>
            ) : null}
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap">
                <input
                  type="text"
                  className="searchBox"
                  placeholder="Search Course"
                  onChange={searchCourse}
                />
                <button className="btn" type="button" onClick={addCourse}>
                  Add Course
                </button>
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Courses
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage
                    showTotalEntries
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
          {/* <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Projects Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns: pColumns, rows: pRows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Course;
