import React from "react";
import PropTypes from "prop-types";

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import MDTypography from "components/MDTypography";

function CoursesTable({ title, data }) {
  return (
    <TableContainer sx={{ padding: 2 }}>
      <MDTypography variant="h6">{title}</MDTypography>
      <Table>
        {/* important display style for alignment of table head */}
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell width="10%">#</TableCell>
            <TableCell width="70%">Course</TableCell>
            <TableCell width="20%">Sales</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((course, index) => (
            <TableRow key={index}>
              <TableCell>{index + 1}</TableCell>
              <TableCell>{course.title}</TableCell>
              <TableCell>
                <MDTypography variant="h6" color="success">
                  {course.sales}
                </MDTypography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CoursesTable.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CoursesTable;
