import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TableEditor from "components/TableEditor";

let tableData = null;

const buttonStyle = {
  marginLeft: "10px",
};

function AntiRagging() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/anti-ragging-policy`;
  const [title, setTitle] = useState("");
  const [committee, setCommittee] = useState(JSON.stringify([[""]]));
  const [policy, setPolicy] = useState("");
  const [note, setNote] = useState("");
  const [policyId, setPolicyId] = useState(null);

  const handleForm = (event, key) => {
    switch (key) {
      case "COMMITTEE":
        setCommittee(JSON.stringify(event));
        break;
      case "POLICY":
        setPolicy(event);
        break;
      case "NOTE":
        setNote(event);
        break;
      default:
        setPolicy(event);
        break;
    }
  };

  const handleTitle = (event) => {
    const { value } = event.target;
    setTitle(value);
  };

  const fetchPolicy = () => {
    axios
      .get(baseUrl)
      .then(async (data) => {
        tableData = data.data.data;
        if (tableData.length > 0) {
          setTitle(tableData[0].title);
          setPolicy(tableData[0].description);
          setCommittee(JSON.stringify(tableData[0].committee || [[""]]));
          setNote(tableData[0].footer_anti_ragging);
          setPolicyId(tableData[0].id);
        } else {
          setTitle("");
          setPolicy("");
          setCommittee(JSON.stringify([[""]]));
          setNote("");
          setPolicyId(null);
        }
      })
      .catch((error) => {
        console.error("Error fetching policy data:", error);
        setCommittee(JSON.stringify([[""]]));
      });
  };

  useEffect(() => {
    fetchPolicy();
  }, []);

  const performAction = async () => {
    const payload = {
      title,
      description: policy,
      committee: JSON.parse(committee),
      footer_anti_ragging: note,
    };

    try {
      let actionEndPoint = policyId ? `/update` : `/add`;

      if (policyId) {
        payload.id = policyId;
        await axios.put(`${baseUrl}${actionEndPoint}`, payload);
      } else {
        const response = await axios.post(`${baseUrl}${actionEndPoint}`, payload);
        setPolicyId(response.data.data.id);
      }

      fetchPolicy(); // Refresh policy data after successful operation
    } catch (error) {
      console.error("Error performing action:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px" }}>
        <MDBox>
          <MDBox mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDInput
                  type="type"
                  label="Title"
                  value={title}
                  fullWidth
                  onChange={(e) => handleTitle(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h5" fontWeight="bold">
                  Anti Ragging Committee Table
                </MDTypography>
                <TableEditor
                  value={JSON.parse(committee)}
                  onChange={(e) => handleForm(e, "COMMITTEE")}
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h5" fontWeight="bold">
                  Anti Ragging Policy
                </MDTypography>
                <MDEditor value={policy} onChange={(e) => handleForm(e, "POLICY")} />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h5" fontWeight="bold">
                  Note
                </MDTypography>
                <MDEditor value={note} onChange={(e) => handleForm(e, "NOTE")} />
              </Grid>
            </Grid>
            <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
              <MDButton variant="outlined" color="info" fullWidth>
                CANCEL
              </MDButton>
              <MDButton
                style={buttonStyle}
                variant="gradient"
                color="info"
                fullWidth
                onClick={performAction}
              >
                SAVE
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default AntiRagging;
