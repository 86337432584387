/**
=========================================================
* Material Dashboard 2 PRO React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 PRO React components
import { Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";

// NewProduct page components
import React, { useEffect, useState } from "react";

const buttonStyle = {
  marginLeft: "10px",
};

function FooterCMS() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/footer`;
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [address, setAddress] = useState("");
  const [phoneNumbers, setPhoneNumbers] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [fb, setFb] = useState("");
  const [twiter, setTwiter] = useState("");
  const [insta, setInsta] = useState("");

  const handleForm = (event, key) => {
    let value;

    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }

    switch (key) {
      case "DESC":
        setDescription(value);
        break;
      case "EMAIL":
        setEmail(value);
        break;
      case "CONTACT":
        setContact(value);
        break;
      case "ADDRESS":
        setAddress(value);
        break;
      case "PHONE_NUMBERS":
        setPhoneNumbers(value);
        break;
      case "LINKEDIN":
        setLinkedIn(value);
        break;
      case "FB":
        setFb(value);
        break;
      case "TWITER":
        setTwiter(value);
        break;
      case "INSTA":
        setInsta(value);
        break;
      default:
        setEmail(value);
        break;
    }
  };

  const setPageInfo = (data) => {
    setId(data.id);
    setDescription(data.footer_description);
    setEmail(data.email);
    setContact(data.contact);
    setAddress(data.address);
    setPhoneNumbers(data.phone_numbers);
    setLinkedIn(data.linkedin_url);
    setFb(data.facebook_url);
    setTwiter(data.twitter_url);
    setInsta(data.instagram_url);
    setEmail(data.email);
  };

  const fetchFooter = () => {
    fetch(baseUrl)
      .then((results) => results.json())
      .then(async (data) => {
        if (data) {
          setPageInfo(data.data[0]);
        }
      });
  };

  useEffect(() => {
    fetchFooter();
  }, []);

  const performAction = async () => {
    const payload = {
      id,
      footer_description: description,
      email,
      contact,
      address,
      phone_numbers: phoneNumbers,
      linkedin_url: linkedIn,
      facebook_url: fb,
      twitter_url: twiter,
      instagram_url: insta,
    };

    let actionEndPoint = "";
    actionEndPoint = `/update`;
    axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
      console.log(response);
      fetchFooter();
    });
  };

  return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Footer
      </MDTypography>
      <MDBox mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox mt={2}>
              <MDInput
                label="Footer Description"
                multiline
                fullWidth
                value={description}
                rows={5}
                onChange={(e) => handleForm(e, "DESC")}
              />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                type="type"
                value={email}
                label="Email"
                fullWidth
                onChange={(e) => handleForm(e, "EMAIL")}
              />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                type="type"
                value={contact}
                label="Contact"
                fullWidth
                onChange={(e) => handleForm(e, "CONTACT")}
              />
            </MDBox>
            <MDBox mt={2}>
              <MDTypography variant="h5" fontWeight="bold">
                Footer Address
              </MDTypography>
              <MDEditor value={address} onChange={(e) => handleForm(e, "ADDRESS")} />
            </MDBox>
            {/* <MDBox mt={2}>
              <MDInput
                label="Footer Address"
                multiline
                fullWidth
                value={address}
                rows={5}
                onChange={(e) => handleForm(e, "ADDRESS")}
              />
            </MDBox> */}
            <MDBox mt={2}>
              <MDInput
                label="Phone Numbers"
                multiline
                fullWidth
                value={phoneNumbers}
                rows={5}
                onChange={(e) => handleForm(e, "PHONE_NUMBERS")}
              />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                type="type"
                value={linkedIn}
                label="LinkedIn url"
                fullWidth
                onChange={(e) => handleForm(e, "LINKEDIN")}
              />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                type="type"
                value={fb}
                label="Facebook url"
                fullWidth
                onChange={(e) => handleForm(e, "FB")}
              />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                type="type"
                value={twiter}
                label="Twiter url"
                fullWidth
                onChange={(e) => handleForm(e, "TWITER")}
              />
            </MDBox>
            <MDBox mt={2}>
              <MDInput
                type="type"
                value={insta}
                label="Instagram url"
                fullWidth
                onChange={(e) => handleForm(e, "INSTA")}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
          <MDButton variant="outlined" color="info" fullWidth>
            CANCEL
          </MDButton>
          <MDButton
            style={buttonStyle}
            variant="gradient"
            color="info"
            fullWidth
            onClick={performAction}
          >
            SAVE
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

export default FooterCMS;
