import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TableEditor from "components/TableEditor";

const buttonStyle = {
  marginLeft: "10px",
};

function AdmissionPage() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/admission-list`;
  const [title, setTitle] = useState("");
  const [list, setList] = useState("");
  const [id, setId] = useState("");

  const handleTitle = (event) => {
    const { value } = event.target;
    setTitle(value);
  };

  const handleForm = (event, key) => {
    switch (key) {
      case "LIST":
        setList(event);
      default:
        setList(event);
        break;
    }
  };

  const fetchAdmission = () => {
    axios
      .get(baseUrl)
      .then(async (response) => {
        console.log("response for fetch", response.data.data);
        setId(response.data.data[0].id);
        setList(response.data.data[0].admission_list);
        setTitle(response.data.data[0].title);
      })
      .catch((error) => {
        console.error("Error fetching policy data:", error);
      });
  };

  useEffect(() => {
    fetchAdmission();
  }, []);

  const performAction = async () => {
    const payload = {
      title,
      admission_list: list,
    };

    try {
      let actionEndPoint = id ? `/update` : `/add`;

      if (id) {
        payload.id = id;
        await axios.put(`${baseUrl}${actionEndPoint}`, payload);
        fetchAdmission();
      } else {
        const response = await axios.post(`${baseUrl}${actionEndPoint}`, payload);
        fetchAdmission();
      }
    } catch (error) {
      console.error("Error performing action:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ height: "100%", padding: "10px 40px 40px 10px" }}>
        <MDBox>
          <MDBox mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDInput
                  type="type"
                  label="Title"
                  value={title}
                  fullWidth
                  onChange={(e) => handleTitle(e)}
                />
              </Grid>
              <Grid item xs={12}>
                <MDTypography variant="h5" fontWeight="bold">
                  Admission list
                </MDTypography>
                <MDEditor value={list} onChange={(e) => handleForm(e, "LIST")} />
              </Grid>
            </Grid>
            <MDBox mt={3} width="20%" display="flex" justifyContent="space-between">
              <MDButton variant="outlined" color="info" fullWidth>
                CANCEL
              </MDButton>
              <MDButton
                style={buttonStyle}
                variant="gradient"
                color="info"
                fullWidth
                onClick={performAction}
              >
                SAVE
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
}

export default AdmissionPage;
