/* eslint-disable no-useless-return */
/* eslint-disable no-unused-vars */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
// import Typography from "@mui/material/Typography";
import "./email-settings.css";
import { Alert, Autocomplete, Chip, Divider, Snackbar } from "@mui/material";

import MDEditor from "components/MDEditor";
import FormField from "layouts/applications/wizard/components/FormField";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// import MDButton from "components/MDButton";

// Data
import { useEffect, useState } from "react";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "axios";
// import PromptModal from "components/PromptModal";
import authorsTableData from "./data/tableData";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  height: "auto",
  width: 600,
  overflow: "auto",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
};

let tableData = null;
let emailTypeData = null;
let EmailTypeId = null;

function EmailSettings() {
  const baseUrl = `${process.env.REACT_APP_BASE_URL}/cms/email-settings`;
  const [open, setOpen] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const [type, setType] = useState("");
  const [emailType, setEmailType] = useState("");
  const [emailTypeCollection, setEmailTypeCollection] = useState([]);
  const [id, setId] = useState("");
  const [typeId, setTypeId] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [signature, setSignature] = useState("");
  const [alertText, setAlertText] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const [modalActionTitle, setModalActionTitle] = useState("ADD");
  const [itemIndex, setItemIndex] = useState(0);

  const handleOpen = () => {
    if (emailTypeCollection.length > 0) setType(emailTypeCollection[0].id);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleTypeOpen = () => {
    setOpenType(true);
  };
  const handleTypeClose = () => setOpenType(false);

  const setForm = (data) => {
    setId(data?.id);
    setType(data?.type);
    setSubject(data?.subject);
    setMessage(data?.message);
    setSignature(data?.signature);
  };

  const setButtonTitle = (buttonTitle) => {
    setModalActionTitle(buttonTitle);
  };

  const setitemIndex = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("UPDATE");
    handleOpen();
  };

  const deleteItem = (index) => {
    // setModalActionTitle("UPDATE");
    setItemIndex(index);
    if (tableData?.length > 0) {
      setForm(tableData[index]);
    }
    setButtonTitle("DELETE");
    handleOpen();
  };

  const clearForm = () => {
    setType("");
    setSubject("");
    setMessage("");
    setSignature("");
  };

  const fetchEmailSettings = () => {
    clearForm();
    axios.get(baseUrl).then(async (data) => {
      // await setTableData(data.resultMessage.data);
      tableData = data.data.data;
      const { cols, rws } = authorsTableData(
        data.data.data,
        setitemIndex,
        deleteItem,
        emailTypeData
      );
      setRows(rws);
      setColumns(cols);
    });
  };

  const fetchEmailType = () => {
    setEmailType("");
    // axios.get(`${baseUrl}/documents`).then(async (data) => {
    axios.get(`${baseUrl}/type`).then(async (data) => {
      console.log(data);
      emailTypeData = data.data.data;
      setEmailTypeCollection(data.data.data);
      fetchEmailSettings();
    });
  };

  useEffect(() => {
    fetchEmailType();
    // fetchEmailSettings();
  }, []);

  const addCategory = () => {
    setForm(null);
    setModalActionTitle("ADD");
    handleOpen();
  };

  const handleForm = (event, key) => {
    console.log(itemIndex);
    const { value } = event.target;
    // const today = new Date(value).toISOString().slice(0, 19).replace("T", " ");
    switch (key) {
      case "TYPE":
        setType(value);
        break;
      case "SUBJECT":
        setSubject(value);
        break;
      case "MESSAGE":
        setMessage(value);
        break;
      case "SIGNATURE":
        setSignature(value);
        break;
      default:
        setType(value);
        break;
    }
  };

  const handleEmailType = (event) => {
    const { value } = event.target;
    setEmailType(value);
  };

  const addEmailType = (typeAction) => {
    setEmailType("");
    const payload = {
      name: emailType,
    };
    let actionEndPoint = "";
    if (typeAction === "ADD") {
      actionEndPoint = "/type/add";
      axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        fetchEmailType();
      });
    } else if (typeAction === "UPDATE") {
      actionEndPoint = `/type/update`;
      axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        fetchEmailType();
      });
    } else if (typeAction === "DELETE") {
      actionEndPoint = `/type/delete/${EmailTypeId}`;
      axios.delete(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        fetchEmailType();
      });
    }
  };

  const deleteEmailType = (emailTypeId) => {
    setTypeId(emailTypeId);
    EmailTypeId = emailTypeId;
    let find = null;
    find = tableData && tableData.filter((item) => item.type_id === emailTypeId);
    if (find && find.length > 0) {
      setAlertText("Type is part of Email Teamplate. Please delete the Template first.");
      setShowAlert(true);
      return;
    }
    addEmailType("DELETE");
  };

  const performAction = async (action = "UPDATE") => {
    const payload = {
      id,
      type_id: type,
      subject,
      message,
      signature,
    };
    let actionEndPoint = "";
    if (action === "ADD") {
      actionEndPoint = "/add";
      axios.post(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchEmailSettings();
      });
    } else if (action === "UPDATE") {
      actionEndPoint = `/update`;
      axios.put(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchEmailSettings();
      });
    } else if (action === "DELETE") {
      actionEndPoint = `/delete/${tableData[itemIndex].id}`;
      axios.delete(`${baseUrl}${actionEndPoint}`, payload).then((response) => {
        console.log(response);
        setOpen(false);
        fetchEmailSettings();
      });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* Add/Eit Modal */}
      <div className="gov-modal-window">
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          autoHideDuration={6000}
          open={showAlert}
          message={alertText}
          severity="error"
          onClose={() => setShowAlert(false)}
        >
          <Alert
            onClose={() => setShowAlert(false)}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {alertText}
          </Alert>
        </Snackbar>
        {/* <PromptModal title={deleteWarning} /> */}
        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mb={2}>
              <Autocomplete
                defaultValue={emailTypeCollection[0]}
                options={emailTypeCollection}
                getOptionLabel={(option) => option.name}
                onChange={(e, v) => setType(v.id)}
                renderInput={(params) => (
                  <FormField {...params} label="Type" InputLabelProps={{ shrink: true }} />
                )}
              />
            </MDBox>
            <MDBox mt={0.5}>
              <MDInput
                type="type"
                label="Subject"
                value={subject}
                fullWidth
                onChange={(e) => handleForm(e, "SUBJECT")}
              />
            </MDBox>
            <MDBox mt={0.5}>
              <MDTypography variant="h6" fontWeight="bold">
                Message
              </MDTypography>
              <MDEditor value={message} onChange={(e) => setMessage(e)} />
            </MDBox>
            <MDBox mt={2}>
              <MDTypography variant="h6" fontWeight="bold">
                Signature
              </MDTypography>
              <MDEditor value={signature} onChange={(e) => setSignature(e)} />
            </MDBox>

            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => performAction(modalActionTitle)}
              >
                {modalActionTitle}
              </MDButton>
            </MDBox>
          </Box>
          {/* </Fade> */}
        </Modal>

        <Modal
          aria-labelledby="spring-modal-title"
          aria-describedby="spring-modal-description"
          open={openType}
          onClose={handleTypeClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          {/* <Fade in={open}> */}
          <Box sx={style}>
            {/* <Typography id="spring-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography> */}
            <MDBox mb={2}>
              <MDInput
                type="type"
                label="Email Type"
                value={emailType}
                fullWidth
                onChange={(e) => handleEmailType(e)}
              />
            </MDBox>

            <Divider />
            <MDBox mt={0.5}>
              {emailTypeCollection.map((item) => (
                <Chip
                  ml={1}
                  label={item.name}
                  key={item.name}
                  variant="outlined"
                  onDelete={() => deleteEmailType(item.id)}
                />
              ))}
            </MDBox>
            <div className="prompt-button-box">
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={() => addEmailType("ADD")}
                >
                  ADD
                </MDButton>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton
                  variant="outlined"
                  color="info"
                  fullWidth
                  onClick={() => setOpenType(false)}
                >
                  Cancel
                </MDButton>
              </MDBox>
            </div>
          </Box>
          {/* </Fade> */}
        </Modal>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <div className="button-wrap">
                <button className="btn" type="button" onClick={addCategory}>
                  Add Email Template
                </button>
                <button className="btn" type="button" onClick={handleTypeOpen}>
                  Add Email Type
                </button>
              </div>

              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Email Templates
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {rows?.length > 0 ? (
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : null}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmailSettings;
