/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

function PlatformSettings(props) {
  const { courses } = props;
  return (
    <Card sx={{ boxShadow: "none" }}>
      <MDBox p={2}>
        <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          Booked courses
        </MDTypography>
      </MDBox>
      <MDBox pt={1} pb={2} px={2} lineHeight={1.25}>
        {courses.length > 0 ? (
          courses.map((item) => (
            <MDBox display="flex" alignItems="center" mb={0.5} ml={-1.5} key={item.id}>
              <MDBox mt={0.5}>
                <MDAvatar src={item.course_img_url} alt={item.coursename} shadow="md" size="sm" />
              </MDBox>
              <MDBox width="80%" ml={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                  {item.coursename}
                </MDTypography>
              </MDBox>
            </MDBox>
          ))
        ) : (
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            No Course Selected
          </MDTypography>
        )}
      </MDBox>
    </Card>
  );
}

// Typechecking props for the ProfilesList
PlatformSettings.propTypes = {
  courses: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PlatformSettings;
