/* eslint-disable camelcase */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function BatchManangementDocuments({ documents, orderId }) {
  const customButtonStyles = ({
    functions: { pxToRem, rgba },
    borders: { borderWidth },
    palette: { transparent, info },
    typography: { size },
  }) => ({
    width: pxToRem(50),
    height: pxToRem(50),
    borderWidth: borderWidth[2],
    mb: 1,
    ml: 0.5,

    "&.MuiButton-contained, &.MuiButton-contained:hover": {
      boxShadow: "none",
      border: `${borderWidth[2]} solid ${transparent.main}`,
    },

    "&:hover": {
      backgroundColor: `${transparent.main} !important`,
      border: `${borderWidth[2]} solid ${info.main} !important`,
      color: rgba(info.main, 0.75),
    },

    "& .material-icons-round": {
      fontSize: `${size["3xl"]} !important`,
    },
  });

  const sendRemainder = () => {
    console.log(orderId);
    console.log("Remind me");
  };

  return (
    <MDBox>
      <MDBox width="80%" textAlign="center" mx="auto" my={4}>
        <MDBox mb={1}>
          <MDTypography variant="h5" fontWeight="regular">
            Supporting Documents uploaded by the Candidate
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3} justifyContent="center">
          {documents && documents.length > 0 ? (
            // eslint-disable-next-line camelcase, no-unused-vars
            documents.map(({ document_name, document_link }, index) => (
              <Grid item xs={12} sm={3} key={index}>
                <MDBox textAlign="center">
                  <MDButton
                    color="info"
                    variant="contained"
                    href={document_link}
                    target="_blank"
                    sx={customButtonStyles}
                  >
                    <Icon sx={{ color: "white" }}>description</Icon>
                  </MDButton>
                  <MDTypography variant="h6" sx={{ mt: 1 }}>
                    {document_name}
                  </MDTypography>
                </MDBox>
              </Grid>
            ))
          ) : (
            <MDBox textAlign="center">
              <h3>No Documents found</h3>
              <MDButton
                color="info"
                variant="contained"
                target="_blank"
                onClick={sendRemainder}
                fullWidth
              >
                <Icon sx={{ color: "white", marginRight: "10px" }}>mail</Icon> Send Remainder
              </MDButton>
            </MDBox>
          )}
          {/* <Grid item xs={12} sm={3}>
            <MDBox textAlign="center">
              <MDButton
                color="info"
                variant={design ? "contained" : "outlined"}
                onClick={handleSetDesign}
                sx={customButtonStyles}
              >
                <Icon sx={{ color: design ? "white" : "inherit" }}>brush</Icon>
              </MDButton>
              <MDTypography variant="h6" sx={{ mt: 1 }}>
                Design
              </MDTypography>
            </MDBox>
          </Grid> */}
          {/* <Grid item xs={12} sm={3}>
            <MDBox textAlign="center">
              <MDButton
                color="info"
                variant={design ? "contained" : "outlined"}
                onClick={handleSetDesign}
                sx={customButtonStyles}
              >
                <Icon sx={{ color: design ? "white" : "inherit" }}>brush</Icon>
              </MDButton>
              <MDTypography variant="h6" sx={{ mt: 1 }}>
                Design
              </MDTypography>
            </MDBox>
          </Grid> */}
          {/* <Grid item xs={12} sm={3}>
            <MDBox textAlign="center">
              <MDButton
                color="info"
                variant={code ? "contained" : "outlined"}
                onClick={handleSetCode}
                sx={customButtonStyles}
              >
                <Icon sx={{ color: design ? "white" : "inherit" }}>integration_instructions</Icon>
              </MDButton>
              <MDTypography variant="h6" sx={{ mt: 1 }}>
                Code
              </MDTypography>
            </MDBox>
          </Grid> */}
          {/* <Grid item xs={12} sm={3}>
            <MDBox textAlign="center">
              <MDButton
                color="info"
                variant={develop ? "contained" : "outlined"}
                onClick={handleSetDevelop}
                sx={customButtonStyles}
              >
                <Icon sx={{ color: design ? "white" : "inherit" }}>developer_mode</Icon>
              </MDButton>
              <MDTypography variant="h6" sx={{ mt: 1 }}>
                Develop
              </MDTypography>
            </MDBox>
          </Grid> */}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

BatchManangementDocuments.propTypes = {
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  orderId: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default BatchManangementDocuments;
